import {
	Fade,
	LinearProgress,
	StandardTextFieldProps,
	TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React, {
	useCallback,
	useEffect,
	useState
} from 'react';

import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { ClickupTask, useClickupApi } from '../../../../services/clickup-api';
import ErrorDisplay from '../../../ErrorDisplay.js';
import useIntlPlus from '../../../hooks/IntlPlus';

const SmallTextField = styled(TextField)(({ theme }) => ({
	'& .MuiInputBase-input': {
		// @ts-ignore
		fontSize: theme.typography.fontSizeSmall ?? 'smaller', // Use theme for responsiveness
	},
}));
interface ClickupTasksTileProps extends StandardTextFieldProps {
	label: string,
	value?: string,
	tasks?:ClickupTask[],
	propertyId?:number|string,
	listId?:string,
	spaceId?:string,
	status?:string,
	initialGridState?:GridInitialStatePremium,
	readonly?: boolean,
	rowFormat?: 'DEFAULT' | 'MARKETING_CALENDAR',
	disableRowLimit?: boolean
	disableRetrieve?: boolean
}

const ClickupTasksTextField: React.FC<ClickupTasksTileProps> = (props) => {
	const intl = useIntlPlus();
	const handleError = ErrorDisplay();
	const api = useClickupApi(handleError);
	const {
		propertyId, listId, spaceId, status,
		onChange, readonly = false,
		rowFormat = 'DEFAULT',
		disableRowLimit = false,
		disableRetrieve = true,
		...inputProps } = props;
	const [value, setValue] = useState<string>(props?.value ?? '');
	const maxLength = props?.inputProps?.maxLength ?? 1024;
	const maxRows = Number(props?.maxRows ?? 0);
	const rowFormatter = useCallback((ct: ClickupTask) =>{
		const name = ct.name;
		const date = ct.due_date ? ` ${ intl.formatDate( ct.due_date ) }` : '';
		const status = ct.status?.status ? ` ${ ct.status?.status }` : '';
		return rowFormat === 'MARKETING_CALENDAR' ? `- ${name}${date}` : `- ${name}${status}${date}`;
	},[]);

	useEffect(()=>{
		if ( !disableRetrieve && !readonly && !value && propertyId && (listId || spaceId) ) {
			api.getTasks({
				params: { propertyId: propertyId?.toString(), spaceId, listId, status, limit: !disableRowLimit && maxRows ? maxRows: undefined }
			}).then( data => {
				const val = data?.map(rowFormatter)?.join('\n');
				setValue(val);
			});
		}
	}, [propertyId, listId, spaceId, readonly]);

	useEffect(()=>{
		// @ts-ignore
		return onChange && onChange( { target: { id: (props.id)!, value } } );
	},[value]);

	const isValid = (value: string ) => {
		return (value.length <= maxLength) && (value.split('\n').length) <= maxRows;
	};

	const handleChange = useCallback( (value: string) => {
		const valid = isValid(value);
		if (!valid) {
			const rows = value.split('\n');
			value = rows.slice(0,maxRows).join('\n');
		}
		setValue(value);
	}, [] );

	return (
		<Box sx={{ width: '100%' }}>
			<Fade in={ api.isLoading } unmountOnExit>
				<LinearProgress  />
			</Fade>
			<SmallTextField
				variant='standard' { ...inputProps }
				value={value}
				onChange={(e)=>handleChange(e.target.value)}
				error={ !isValid(value) }
				helperText={
					value.length > maxLength ? (<span>Max text length exceeded.</span>) :
						value.split('\n').length === maxRows ? (<span style={{ color: 'darkmagenta' }}>Max rows is {maxRows}.</span>) :
							value.split('\n').length > maxRows ? (<span>Max rows exceeded.</span>) :
								value.length === maxLength ? (<span style={{ color: 'darkmagenta' }}>Max text length is { maxLength }</span>) : undefined
				}
			/>
		</Box> );
};


export default ClickupTasksTextField;
