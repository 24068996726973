import { MenuItem } from '@mui/material';
import React, {
	useEffect,
	useState
} from 'react';
import { useIntl } from 'react-intl';
import { useWatchlistApi } from '../../services/watchlist-api';
import { EditDataDialog } from '../EditDataDialog';
import ErrorDisplay from '../ErrorDisplay';
import InputField from '../InputField';
import PropertyAutocomplete from '../Property/PropertyAutocomplete';
import {
	WatchlistPropertySchema,
} from './schema';
import {
	Watchlist,
	WatchlistProperty
} from './types';
const WATCHLIST_ID = 1;
export interface WatchlistPropertyDialogProps {
	open:boolean,
	onClose: any,
	onSave: any,
	watchlistProperty?: WatchlistProperty
}
const WatchlistPropertyDialog: React.FC<WatchlistPropertyDialogProps> = (
	{ open,
		onClose,
		onSave,
		watchlistProperty, ...props}) => {
	const intl = useIntl();
	const handleError = ErrorDisplay();
	const api = useWatchlistApi( handleError );
	const [ watchlist, setWatchlist ] = useState<Watchlist>({id: WATCHLIST_ID} as Watchlist);

	const handleSave = async (data:WatchlistProperty, config:any)=> {
		const ret = await api.saveWatchlistProperty( data.id!, data, config );
		onSave && onSave( ret );
		onClose && onClose();
	};

	useEffect( () => {
		api.getOne( WATCHLIST_ID )
			.then( ( data: Watchlist ) => {
				setWatchlist( data );
			} );
	}, [ ] );

	return (
		<EditDataDialog
			title='Add Watchlist Community'
			open={ open }
			onClose={ onClose }
			onSave={ handleSave }
			schema={WatchlistPropertySchema}
			data={{ ...watchlistProperty, watchlist }}
			method='PUT'
		>
			<PropertyAutocomplete
				id="property"
				label={ intl.formatMessage( { id: 'property', defaultMessage: 'Community' } ) }
				required
				enableAddDialog={ false }
				readOnly={ !!watchlistProperty?.property }
			/>
			<InputField
				id='state'
				label='State'
				type='select'
				options={watchlist?.config?.states ?? []}
				renderOption={(option:string)=>(<MenuItem key={option} value={option}>{option}</MenuItem>)}
				value={'Initial Escalation'}
				required={ true }
				readonly={ true }
			/>
			<InputField
				id='reason'
				label='Main Concern'
				type='select'
				options={watchlist?.config?.reasons ?? []}
				renderOption={(option:string)=>(<MenuItem key={option} value={option}>{option}</MenuItem>)}
				required={ true }
			/>

		</EditDataDialog>);
};
export default WatchlistPropertyDialog;
