import { useAbility } from '@casl/react';
import {
	useGridApiRef
} from '@mui/x-data-grid-premium';
import { useAuth } from 'base-shell/lib/providers/Auth';
import Page from 'material-ui-shell/lib/containers/Page';
import React, {
	useCallback,
	useEffect,
	useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { AbilityContext } from '../../components/AbilityContext';
import ErrorDisplay from '../../components/ErrorDisplay';
import GlobalSearch from '../../components/GlobalSearch';
import CoreDataGrid from '../../components/grid/CoreDataGrid';
import PageTitle from '../../components/hooks/PageTitle';
import {
	Watchlist,
} from '../../components/Watchlist/types';
import WatchlistPropertyDialog, { WatchlistPropertyDialogProps } from '../../components/Watchlist/WatchlistPropertyDialog';
import { WatchlistContext } from '../../components/Watchlist/WatchlistContext';
import useWatchlistPropertiesGridColumns from '../../components/Watchlist/WatchlistPropertiesGridColumns';
import { useWatchlistApi } from '../../services/watchlist-api';

const WatchlistDetail = () => {
	const { auth } = useAuth();
	const ability = useAbility( AbilityContext );
	const [refresh, setRefresh] = useState( true );
	const [ tab, setTab ] = useState<number>( 0 );
	const [ watchlist, setWatchlist ] = useState<Watchlist>({} as Watchlist);
	const location = useLocation();
	const id = Number( location.pathname.split( '/' )[2] );
	const handleError = ErrorDisplay();
	const api = useWatchlistApi( handleError );
	const [ canEdit, setCanEdit ] = useState();
	useEffect( () => {
		setCanEdit( ability.can( 'update', 'Watchlist' ) );
		if ( auth?.isAuthenticated && refresh ) {
			setRefresh( false );
			api.getOne( id )
				.then( ( data: Watchlist ) => {
					setWatchlist( data );
				} );
		}
	}, [ id, auth, refresh ] );

	function TabPanel( props: any ) {
		const { children, value, index, ...other } = props;

		return (
			<div
				style={ { display: 'flex', height: '100%' } }
				role="tabpanel"
				hidden={ value !== index }
				id={ `full-width-tabpanel-${ index }` }
				aria-labelledby={ `full-width-tab-${ index }` }
				{ ...other }
			>
				{ value === index && children }
			</div>
		);
	}

	const handleChange = ( event: any, newValue: number ) => {
		setTab( newValue );
	};
	const handleChangeIndex = ( index: number ) => {
		setTab( index );
	};

	function applyTabProps( index: number ) {
		return {
			id: `full-width-tab-${ index }`,
			'aria-controls': `full-width-tabpanel-${ index }`,
		};
	}

	PageTitle( { id: watchlist?.name, defaultTitle: watchlist?.name } );
	const watchlistPropertyApi = api.watchlistPropertyApi(id);
	const gridApiRef = useGridApiRef();
	const columns = useWatchlistPropertiesGridColumns( { watchlist });
	const AddDialog = useCallback((props:WatchlistPropertyDialogProps)=>{
		return <WatchlistPropertyDialog
			{ ...props }
			onClose={async (saved:boolean)=> {
				props.onClose && props.onClose( saved );
				setRefresh( true );
			}}
		/>;
	},[watchlist?.config]);
	const DEFAULT_ROW_HEIGHT = 52;
	const getRowHeight = (params:any) => {
		// Adjust multiplier as needed based on font size and line spacing
		const lineCount = params.model?.latestStatus?.data?.status?.trim().split('\n').length || 1;
		const rowHeight = (lineCount > 3 ? 3 : lineCount) * 32 * params.densityFactor;
		return rowHeight > DEFAULT_ROW_HEIGHT ? rowHeight : DEFAULT_ROW_HEIGHT * params.densityFactor;
	};
	return (
		<WatchlistContext.Provider value={ [ watchlist, setWatchlist ] }>
			<Page
				isLoading={ api.isLoading || !watchlist?.id }
				pageTitle={ `${ watchlist?.name }` }
				appBarContent={
					<>
						<GlobalSearch/>
					</>
				}
				// tabs={
				// 	<AppBar position="static">
				// 		<Tabs
				// 			value={ tab }
				// 			onChange={ handleChange }
				// 			textColor="inherit"
				// 			indicatorColor="secondary"
				// 			variant={ 'fullWidth' }
				// 			scrollButtons={ true }
				// 			allowScrollButtonsMobile={ true }
				// 		>
				// 			<Tab label="Home"  { ...applyTabProps( 0 ) } />
				// 			<Tab label="Communities"  { ...applyTabProps( 1 ) }/>
				// 			<Tab label={ <AuditInfo auditable={ watchlist } sx={ undefined } onClick={ undefined }/> }
				// 				sx={ { alignSelf: 'end', alignItems: 'end' } } { ...applyTabProps( 2 ) }/>
				// 		</Tabs>
				// 	</AppBar>
				// }
			>
				<CoreDataGrid
					api = { watchlistPropertyApi }
					gridApiRef={ gridApiRef }
					data = { watchlist?.properties ?? [] }
					setRefresh={ setRefresh }
					columns={ columns }
					AddDialog={ AddDialog }
					disableGridViewEditing={false}
					disableGridViewSelector={false}
					initialGridView={{editable: true}}
					getRowHeight={(params:any) => getRowHeight(params)}
					// sx={{
					// 	'& .MuiDataGrid-cell': {
					// 		alignItems: 'flex-start', // Aligns text to the top of the cell
					// 		paddingTop: 1, // Optional: Adjust padding for visual appearance
					// 	},
					// }}
				/>
			</Page>
		</WatchlistContext.Provider>
	);
};
export default WatchlistDetail;
