import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { Component } from 'react';
//@ts-ignore
import App from 'base-shell/lib';
import _config from './config';
import { LicenseInfo } from '@mui/x-license-pro';
import { FlagProvider } from '@unleash/proxy-client-react';
//@ts-ignore
import { ConfirmProvider } from 'material-ui-confirm';
import { AbilityContext } from './components/AbilityContext';
import { createMongoAbility } from '@casl/ability';

LicenseInfo.setLicenseKey(window.env.muiLicenseKey);

type Actions = 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Property' | 'Project';


export default class ProjectTools extends Component {
	render() {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		let { url } = window.env.unleash;
		try {
			url = new URL(url);
		} catch (e) {
			//assume relative
			url = new URL(url, document.baseURI);
		}
		return <React.StrictMode>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
			<AbilityContext.Provider value = {createMongoAbility<[Actions, Subjects]>()}>
				<FlagProvider config={{ ...window.env.unleash, url }}>
					<ConfirmProvider>
						<App config={_config} />
					</ConfirmProvider>
				</FlagProvider>
			</AbilityContext.Provider>
			</LocalizationProvider>
		</React.StrictMode>;
	}
}
