import {useState} from 'react';
import { CoreGridGenericApi } from '../components/grid/types';
import {
	Watchlist,
	WatchlistProperty
} from '../components/Watchlist/types';
import {ApiCore} from './common/api-core';
import useApiClient from './common/api-client';

const url = 'watchlist';

const API_BASE_URL = '/api';
const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: url,
};

export const useWatchlistApi = (errorCallback: any) => {
	const client = useApiClient( { baseURL: API_BASE_URL } );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const api = new ApiCore( apiOptions, client );

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		//return response.data || initialData;
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) return;
		const data = response.data;
		if ( errorCallback ) {
			return errorCallback( data );
		}
		throw data;
	};

	const getAll = async ( config?: any ) => {
		setIsLoading( true );
		return api.getAll( config )
			.then( handleResponse )
			.catch( handleError );
	};

	const getOne = async ( id: number, config?: any ) => {
		setIsLoading( true );
		return api.getSingle( id, config )
			.then( handleResponse )
			.catch( handleError );
	};

	const save = async ( watchlist: Watchlist ) => {
		if ( watchlist.id ) {
			return api.put( watchlist )
				.then( handleResponse )
				.then( response => {
					return response;
				} )
				.catch( handleError );
		}
		return api.post( watchlist )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};
	const deleteWatchlist = async ( id: number ) => {
		return api.delete( id )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const getWatchlistProperties = async ( id: number, config?: any ) => {
		setIsLoading( true );
		return api.getProvider().getAll( `${ apiOptions.url }/${ id }/properties`, config )
			.then( handleResponse )
			.catch( handleError );
	};



	const saveWatchlistProperty = async ( id: number, model: WatchlistProperty, config: any ) => {
		if ( model?.id ) {
			return api.getProvider().put( `${ apiOptions.url }/${ id }/properties/${model.id}`, model, config )
				.then( handleResponse )
				.then( response => {
					return response;
				} )
				.catch( handleError );
		}
		return api.getProvider().post(`${ apiOptions.url }/${ id }/properties`, model, config )
			.then( handleResponse )
			.then( response => {
				return response;
			} )
			.catch( handleError );
	};

	const removeWatchlistProperty = async (propertyId: number, id: number, config: any) => {
		return api.getProvider().delete(`${apiOptions.url}/${propertyId}/properties`, id, config)
			.then(handleResponse)
			.then(response => {
				return response;
			})
			.catch(handleError);
	};

	const watchlistPropertyApi = ( propertyId: number ):CoreGridGenericApi<WatchlistProperty> => {
		return {
			typeSingle: 'Watchlist',
			typePlural: 'Watchlists',
			hasError,
			isLoading,
			getAll: (id: number, config?: any ): Promise<WatchlistProperty[]> =>{
				return getWatchlistProperties(id, config);
			},
			save: ( data: WatchlistProperty, config: any ): Promise<WatchlistProperty> => {
				return saveWatchlistProperty(propertyId!, data, config);
			},
			remove: ( id:number, config: any ): Promise<WatchlistProperty> => {
				return removeWatchlistProperty(propertyId, id!, config);
			}
		};
	};

	return {
		typeSingle: 'Watchlist',
		typePlural: 'Watchlists',
		isLoading,
		hasError,
		getAll,
		getOne,
		save,
		remove: deleteWatchlist,
		watchlistPropertyApi,
		getWatchlistProperties,
		saveWatchlistProperty,
		removeWatchlistProperty,
	};
};
