import React from 'react';
import { Card, CardActions, CardContent, CardHeader, IconButton } from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import { EditDataDialog } from './EditDataDialog';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import DisplayField from './DisplayField';
import InputField from './InputField';
import useIntlPlus from './hooks/IntlPlus';

const DEFAULT_ITEM_SX = {
	padding: 0,
	height: 320,
	textAlign: 'left',
	verticalAlign: 'top',
	fontSize: '.875rem',
};

const Item = styled(Card)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	color:
		theme.palette.mode === 'dark' ? '#fff' : theme.palette.text.secondary,
}));

const defaultRenderTileContent = ({ fields, data, intl }) =>{
	return fields?.map(f => {
		if (f) {
			const value = f.formatValue ? f.formatValue(f.value || get(data, f.id)) : f.value || get(data, f.id);
			if (f.render) return f.render({ ...f, value });
			return (
				f.id ?
					< DisplayField
						key={ f.name }
						id={ f.id }
						label={ f.label }
						value={ value }
						type={ f.type }
						title={ f.description }
						masked={ f.masked }
						readOnly={ f.readOnly }
						href={ f.href }
						color={ f.color }
						copy={ f.copy }
					>{f.renderChildren && f.renderChildren({ ...f, value }) }</DisplayField> : ''
			);
		}
		return null;
	});
};

const Tile = ({
	title,
	title2,
	fields = [],
	data,
	setData,
	onSave,
	schema,
	editProps,
	readonly = false,
	editable = false,
	renderTileContent = defaultRenderTileContent,
	EditComponent = EditDataDialog,
	sx,
	children,
	footer
}) => {
	const [open, setOpen] = React.useState(false);
	const intl = useIntlPlus();
	const maxHeight = title ? 'calc(100% - 30px)' : '100%;';
	const handleClickEdit = async () => {
		setOpen(true);
	};
	const handleClose = (data) => {
		if (data) {
			setData(data);
		}
		setOpen(false);
	};
	const EditAction = onSave ? (
		readonly ? <LockIcon/> :
			<IconButton
				title='Edit'
				size="small"
				startIcon={ <EditIcon /> }
				onClick={ handleClickEdit }
				disabled={!onSave || !data?.id || !editable}
			>
				<EditIcon />
			</IconButton>
	): null;
	const editDialog = onSave ? (<EditComponent
		{ ...editProps }
		title={ title }
		open={ open }
		data={ data }
		setData={ setData }
		onClose={ handleClose }
		onSave={ onSave }
		schema={ schema }
		typeLabel={ title }
	>
		{ fields?.flatMap(f => f ? f.renderInput ? f.renderInput(f) : <InputField { ...f } /> : null)}
	</EditComponent>):null;
	return <Item key={title} sx={ { ...DEFAULT_ITEM_SX, ...sx }} style={{ display: 'flex', flexDirection: 'column' }}>
		{ editDialog }
		{ title && <CardHeader title={ title + (title2 ? ': ' + title2 : '') } action={ EditAction }
			sx={ {padding: '8px', '& .MuiCardHeader-title': { fontSize: '1.10rem' }}}
		/>
		}
		<CardContent sx={{ paddingTop: '0px', paddingBottom: '0px', overflow: 'auto', maxHeight: maxHeight }}>
			{renderTileContent({ fields, data, intl })}
			{ children }
		</CardContent>
		<CardActions style={{ marginTop: 'auto', padding: '4px' }}>
			{ footer }
		</CardActions>
	</Item>;
};
Tile.propTypes = {
	title: PropTypes.string,
	title2: PropTypes.string,
	fields: PropTypes.array,
	data: PropTypes.object,
	setData: PropTypes.func,
	onSave: PropTypes.func,
	schema: PropTypes.object,
	editProps: PropTypes.object,
	readonly: PropTypes.bool,
	editable: PropTypes.bool,
	renderTileContent: PropTypes.func,
	EditComponent: PropTypes.func,
	sx: Promise.object,
	children: PropTypes.any,
	footer: PropTypes.any,
	renderChildren: PropTypes.func,
};

export default Tile;
