import { useAbility } from '@casl/react';
import {
	Clear,
	CopyAll,
	FileCopy,
	Refresh,
	Restore
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Autocomplete,
	Button,
	FormControl,
	FormControlLabel,
	IconButton,
	LinearProgress,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Switch,
	SxProps,
	TextField,
	Theme
} from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { GridRowParams } from '@mui/x-data-grid';
import {
	DataGridPremium,
	GRID_DETAIL_PANEL_TOGGLE_FIELD,
	GridActionsCellItem,
	GridCallbackDetails,
	GridColDef,
	gridFilteredSortedRowIdsSelector,
	GridFilterModel,
	GridMenuIcon,
	GridPaginationModel,
	GridSortModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext,
	useGridApiEventHandler
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium.js';
import { GridFeatureMode } from '@mui/x-data-grid/models/gridFeatureMode';
import {
	GridRowId,
	GridRowIdGetter,
	GridRowModel,
	GridValidRowModel
} from '@mui/x-data-grid/models/gridRows';
import {
	GridRowHeightParams,
	GridRowHeightReturnValue
} from '@mui/x-data-grid/models/params';
// @ts-ignore
import { useAuth } from 'base-shell/lib/providers/Auth';

import isEmpty from 'lodash/isEmpty';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import React, {
	MutableRefObject,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react';
import {
	useLocation,
	useNavigate,
	useSearchParams
} from 'react-router-dom';
import { useAuditApi } from '../../services/audit';
import { useViewsApi } from '../../services/views-api';
import { AbilityContext } from '../AbilityContext';
import { hasRole } from '../AuthUtils';
import ErrorDisplay from '../ErrorDisplay';
import GridViewDefinitionDialog from '../grid/GridViewDefinitionDialog';
import { GridBulkEditDialog } from '../GridBulkEditDialog';
import { GridViewEditDialog } from '../GridViewEditDialog';
import useGridHelper from '../hooks/GridHelper';
import useIntlPlus from '../hooks/IntlPlus';
import useLocalStorage from '../hooks/LocalStorage';
import {
	CoreGridGenericApi,
	UIGridView
} from './types';

const DEFAULT_GRID_VIEW: Partial<UIGridView<any>> = {
	name: 'Default',
	default: true,
	system: true,
	editable: false,
	category: 'My Views',
	state: {
		columns: {
			lookup: {},
			orderedFields: [ 'actions' ],
			columnVisibilityModel: {
				actions: true,
				[GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
			}
		},
		pagination: {
			paginationModel: {
				'page': 0,
				'pageSize': 100
			}
		},
		pinnedColumns: {
			'right': [
				'actions'
			]
		},
	}
};


export type CoreDataGridProps<R extends GridValidRowModel = any> = {
	gridApiRef: MutableRefObject<GridApiPremium>,
	data: R[],
	rowCount?: number,
	setRefresh: Function,
	api: CoreGridGenericApi<R>,
	columns: GridColDef<R>[],
	AddDialog?: React.FC<any>,
	getRowId?: GridRowIdGetter<R>;
	scope?: string
	enableBulkEdit?: boolean
	disableGridViewEditing?: boolean
	disableGridViewSelector?: boolean
	enableMeMode?: boolean,
	slots?: any
	defaultGroupingExpansionDepth?: number,
	getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
	getDetailPanelHeight?: ( params: GridRowParams<R> ) => number | 'auto'
	getDetailPanelContent?: ( params: GridRowParams<R> ) => React.ReactNode;
	initialGridView?: Partial<UIGridView<R>>
	navigateOnAdd?: boolean
	gridViewsNamespace?: string
	gridViewChangeHandler?: ( view: UIGridView<R> ) => boolean
	filterModelCallback?: ( model: GridFilterModel ) => void
	sortModelCallback?: ( model: GridSortModel ) => void
	paginationModelCallback?:(model:GridPaginationModel)=> void
	rowGroupingColumnMode?: 'single' | 'multiple'
	slugPrefix?: string,
	paginationMode?: GridFeatureMode,
	filterMode?: GridFeatureMode,
	sortingMode?: GridFeatureMode,
	serverSideExport?: (  ) => boolean,
	sx?:SxProps<Theme>
};

const CoreDataGrid = <R extends GridValidRowModel = any>( props: CoreDataGridProps<R> ) => {
	const {
		gridApiRef,
		data,
		setRefresh,
		api,
		columns,
		AddDialog,
		enableBulkEdit = false,
		disableGridViewEditing = false,
		disableGridViewSelector = false,
		enableMeMode = false,
		slots,
		defaultGroupingExpansionDepth = -1,
		navigateOnAdd,
		gridViewsNamespace,
		getRowId,
		initialGridView = {},
		getRowHeight,
		getDetailPanelHeight,
		getDetailPanelContent,
		gridViewChangeHandler = ( view ) => true,
		filterModelCallback = ( model: GridFilterModel ) => { return; },
		sortModelCallback = ( model: GridSortModel ) => { return; },
		paginationModelCallback = ( model: GridPaginationModel ) => { return; },
		rowGroupingColumnMode = 'single',
		slugPrefix = '',
		paginationMode = 'client',
		filterMode = 'client',
		sortingMode = 'client',
		rowCount,
		serverSideExport,
		sx
	} = props;
	const type = api.typeSingle;
	const typeLowerCase = type.toLowerCase();
	const [ gridViewType, setGridViewType ] = useState<string>(
		`${ typeLowerCase }${ gridViewsNamespace ? '-' + gridViewsNamespace : '' }` );
	const [ gridColumns, setGridColumns ] = useState<GridColDef<R>[]>( [] );
	const [ dialogOpen, setDialogOpen ] = useState<boolean>( false );
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const handleError = ErrorDisplay();
	const intl = useIntlPlus();
	const viewsApi = useViewsApi( handleError );
	const auditApi = useAuditApi( handleError );
	const [ rows, setRows ] = useState<R[]>( [] );
	const [ rowData, setRowData ] = useState<Partial<R>>( {} );
	const { GridAggregationFunctions, mergeUpdatesById } = useGridHelper<R>();
	const { auth } = useAuth();
	const ability = useAbility( AbilityContext );
	const [ gridViews, setGridViews ] = useState<UIGridView<R>[]>( [] );
	const [ gridView, setGridView ] = useLocalStorage( `${ gridViewType }-grid-view`,
		{ ...DEFAULT_GRID_VIEW, ...initialGridView } );
	const [ editState, setEditState ] = useState( {} );
	const [ isModelModified, setIsModelModified ] = useState( false );

	const [ loadGridViews, setLoadGridViews ] = useState( false );
	const [ showAddDialog, setShowAddDialog ] = useState( false );
	const [ showEditDialog, setShowEditDialog ] = useState( false );
	const [ showBulkEditDialog, setShowBulkEditDialog ] = useState( false );
	const [ groupingExpansionDepth, setGroupingExpansionDepth ] = useState( defaultGroupingExpansionDepth );
	const [ detailPanelExpandedRowIds, setDetailPanelExpandedRowIds ] = React.useState( [] );
	const [ selectedRows, setSelectedRows ] = useState<Map<GridRowId, GridRowModel>>();
	const [ canCreateRow, setCanCreateRow ] = useState<boolean>( ability.can( 'create', type ) );
	const [ canUpdateRow, setCanUpdateRow ] = useState<boolean>( ability.can( 'update', type ) );
	const [ canDeleteRow, setCanDeleteRow ] = useState<boolean>( ability.can( 'delete', type ) );
	const [ canCreateUIGridView, setCanCreateUIGridView ] = useState<boolean>( ability.can( 'update', 'UIGridView' ) );
	const [ canUpdateUIGridView, setCanUpdateUIGridView ] = useState<boolean>( ability.can( 'update', 'UIGridView' ) );
	const [ canDeleteUIGridView, setCanDeleteUIGridView ] = useState<boolean>( ability.can( 'delete', 'UIGridView' ) );
	const [ searchParams ] = useSearchParams();
	const [ meMode, setMeMode ] = useState(Boolean(searchParams.get( 'meMode' )) ?? false );
	const confirm = useConfirm();
	const navigate = useNavigate();
	const location = useLocation();
	const [ host, route, view ] = location.pathname.split( '/' );
	const inputRef: React.RefObject<any> = useRef( '' );
	const auditNote = useRef( '' );
	const editRow = useCallback(
		( params: GridRowParams<R> ) => () => {
			setRowData( params.row );
			setShowEditDialog( true );
		},
		[ gridColumns, canUpdateRow ]
	);
	const copyRow = useCallback(
		( params: any ) => async () => {
			confirm( {
				title: `Copy ${ intl.formatMessage( { id: typeLowerCase } ) }`,
				description: `Copy ${ params.row?.name }?`
			} )
				.then( async () => {
					if ( !api.copy ) {
						return;
					}
					const data = await api?.copy( params.row.id );
					if ( data && data.id ) {
						setRefresh( true );
						enqueueSnackbar( `Copied ${ data.name }`, {
							variant: 'info',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'center'
							}
						} );
					}
				} )
				.catch( ( e ) => { e && console.error( e ); } );
		},
		[ gridColumns ]
	);
	const deleteRow = useCallback(
		( params: any ) => async () => {
			confirm( {
				title: `Delete ${ intl.formatMessage( { id: typeLowerCase } ) }`,
				description: `Delete ${ params.row?.name }?`,
				content: <div><TextField
					label="Save Note"
					helperText="Enter an optional note."
					variant="outlined"
					fullWidth
					margin="normal"
					multiline
					rows={ 4 } // Adjust number of rows as needed
					onChange={ ( event ) => {
						auditNote.current = ( event.target.value );
					} }
				/></div>
			} )
				.then( async () => {
					const config = auditNote.current ? { headers: { 'x-audit-note': auditNote.current } } : undefined;
					const data = await api.remove!( params.row.id, config );
					if ( data && data.deleted ) {
						setRefresh( true );
						enqueueSnackbar( `Deleted ${ data.deleted || 0 } ${ type }`, {
							variant: 'info',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'center'
							}
						} );
					}
				} )
				.catch( ( e ) => {
					e && console.error( e );
				} );
		}, [ gridColumns, canDeleteRow ] );

	const restoreRow = useCallback(
		( params: any ) => async () => {
			api.restore && confirm( {
				title: `Restore ${ intl.formatMessage( { id: typeLowerCase } ) }`,
				description: `Restore ${ params.row?.name }?`
			} )
				.then( async () => {
					const data = await api.restore!( params.row.id );
					if ( data && data.id ) {
						setRefresh( true );
						enqueueSnackbar( `Restore ${ data.name } ${ type }`, {
							variant: 'info',
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'center'
							}
						} );
					}
				} )
				.catch( ( e ) => { e && console.error( e ); } );
		}, [ api, api.restore ] );

	const canEditGridRow = ( gridView: UIGridView<R>, gridColumns: GridColDef<R>[], params: any ) => {
		const canEdit = gridColumns
			&& ( gridView?.editable && canUpdateRow && hasRole( auth, gridView?.role )
			&& gridColumns.find( c => c.editable === true ) );
		return canEdit;

	};

	const canCopyGridRow = ( params: any ) => {
		return api.copy && params.row?.id && canCreateRow;
	};

	const canDeleteGridRow = ( params: any ) => {
		return params.row?.id && canDeleteRow;
	};

	const handleProcessRowUpdateError = useCallback( ( error: any ) => {
		enqueueSnackbar( error.message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		} );
	}, [] );

	useEffect( () => {
		if ( gridColumns ) {
			setGridViewType( `${ typeLowerCase }${ gridViewsNamespace ? '-' + gridViewsNamespace : '' }` );
			setRows( data );
			gridApiRef.current.autosizeColumns( {
				includeHeaders: true,
				includeOutliers: true,
				outliersFactor: 1.5,
				expand: true,
			} );
		}
	}, [ data, gridApiRef, gridViewsNamespace, typeLowerCase, gridColumns ] );

	useEffect( () => {
		setCanCreateRow( ability.can( 'create', type ) );
		setCanUpdateRow( ability.can( 'update', type ) );
		setCanDeleteRow( ability.can( 'delete', type ) );
		setCanCreateUIGridView( ability.can( 'update', 'UIGridView' ) );
		setCanUpdateUIGridView( ability.can( 'update', 'UIGridView' ) );
		setCanDeleteUIGridView( ability.can( 'delete', 'UIGridView' ) );
		if ( loadGridViews || isEmpty( gridViews ) ) {
			// console.debug('loading grid views');
			setLoadGridViews( false );
			viewsApi.findAll( gridViewType )
				.then( ( r ) => {
					setGridViews( r );
					const viewId = searchParams.get( 'view' );
					const view = viewId
						? r?.find( ( v: any ) => v.slug === viewId )
						: gridView?.id
							? r?.find( ( v: any ) => v.id === gridView?.id )
							: r?.at( 0 );
					if ( view && view?.id !== gridView?.id ) {
						gridApiRef.current.restoreState( view.state );

						setGridView( view );
					}
					gridViewChangeHandler( view );
				} );
		}
	}, [ loadGridViews, auth, setGridView, gridApiRef, gridViewType ] );

	const handleClickAdd = async () => {
		setShowAddDialog( true );
	};
	const handleClickBulkEdit = async () => {
		setShowBulkEditDialog( true );
	};
	const handleClose = ( saved: any ) => {
		setShowAddDialog( false );
		setShowEditDialog( false );
		setRowData( {} );
		setRefresh( saved );
	};

	const CustomToolbar =// useCallback(
			() => {
				const gridApiContext = useGridApiContext();
				const [ anchorEl, setAnchorEl ] = useState( null );

				const handleMenuOpen = ( event: any ) => {
					setAnchorEl( event.currentTarget );
				};

				const handleMenuClose = () => {
					setAnchorEl( null );
				};

				const toggleMeMode = useCallback ((event:any) => {
					const {target: {checked}} = event;
					setMeMode( checked );
					checked ? searchParams.set('meMode', checked) : searchParams.delete( 'meMode' );
					const newPath = `${ location.pathname }?${ searchParams.toString() }`;
					navigate( newPath, { replace: true } );
				},[]);

				const processGridViewChange = async ( value: UIGridView<R> ) => {
					let view = value ?? gridViews?.find( ( v: UIGridView<R> ) => v.default );
					gridApiContext.current.restoreState( view.state );
					setGridView( view );
					searchParams.set( 'view', view.slug );
					meMode ? searchParams.set('meMode', meMode.toString()) : searchParams.delete( 'meMode' );
					const newPath = `${ location.pathname }?${ searchParams.toString() }`;
					setIsModelModified( false );
					navigate( newPath, { replace: true } );
					return gridViewChangeHandler( value );
				};

				const handleGridViewChange = async ( event: React.SyntheticEvent,
					value: UIGridView<R> ) => {
					if ( value?.id === gridView?.id ) {
						return false;
					}
					if ( gridView?.permissions?.update && isModelModified && !gridView?.system ) {
						const view = getGridView();
						await saveGridView( view );
					}

					return await processGridViewChange( value );
				};

				const generateViewName = ( base = 'New View' ) => {
					let index = 0;
					while ( gridViews?.find( ( v: any ) => v.name === `${ base } ${ index }` ) ) {
						index++;
					}
					return `${ base } ${ index }`;
				};

				const newGridView = async () => {
					const state = gridApiContext.current.exportState();
					const name = generateViewName( gridView?.name ?? 'New View' );
					const slug = canCreateUIGridView ? `${ slugPrefix }${ name?.toLowerCase().replaceAll( /\W/g, '_' ) }`
						: `${ slugPrefix }crypto.randomUUID()}`;
					const view = {
						name,
						slug,
						type: gridViewType,
						category: 'My Views',
						editable: false,
						system: false,
						state
					};
					setEditState( view );
					setDialogOpen( true );
				};

				const getGridView = () => {
					const state = gridApiContext.current.exportState();
					return { ...gridView, state };
				};

				const editGridView = async () => {
					const view = getGridView();
					setEditState( view );
					setDialogOpen( true );
				};

				const saveGridView = async ( view: any ) => {
					if ( !viewsApi.isLoading ) {
						if ( !view.type ) {
							view.type = gridViewType;
						}
						if ( !view.slug.startsWith( slugPrefix ) ) {
							view.slug = `${ slugPrefix }${ view.slug }`;
						}
						const res = await viewsApi.save( view );
						if ( res?.id ) {
							enqueueSnackbar( `Saved ${ intl.formatMessage( {
								id: typeLowerCase,
								defaultMessage: 'Grid'
							} ) } View: ${ view.name }`, {
								variant: 'success',
								preventDuplicate: true,
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'center'
								}
							} );
							if ( !view?.id ) {
								const newPath = `${ location.pathname }?view=${ view.slug }`;
								//const newPath = `/${ location.pathname }?view=${view.slug}`;
								navigate( newPath, { replace: true } );
							}
							setLoadGridViews( true );
							setGridView( res );
							setIsModelModified( false );
							return res;
						}
					}
					setIsModelModified( false );
				};

				const copyGridViewLink = async ( view: any ) => {
					const fullUrl = `${ window.location.origin }${ location.pathname }?view=${ view.slug }`;
					await navigator.clipboard.writeText( fullUrl );
					enqueueSnackbar( `Copied ${ view.name } url to clipboard`, {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					} );
				};

				const deleteGridView = async ( gridView: UIGridView<R>, gridViews: UIGridView<R>[] ) => {
					if ( gridView?.id ) {
						confirm( { title: 'Delete View', description: `Delete ${ gridView?.name }?` } )
							.then( async () => {
								enqueueSnackbar( `Deleted ${ gridView.name }`, {
									variant: 'info',
									anchorOrigin: {
										vertical: 'top',
										horizontal: 'center'
									}
								} );
								const { id } = gridView;
								await viewsApi.remove( id );
								setIsModelModified( false );
								setGridViews( gridViews.filter( ( v: any ) => v?.id !== id ) );
								setGridView( gridViews?.at( 0 ) );
							} )
							.catch( ( e ) => { e && console.error( e ); } );
					}
				};

				const handleBeforeUnmount = async ( event: any, details: any ) => {
					// Prevent executing the cleanup logic multiple times
					if ( !gridApiContext.current ) {
						console.error( 'Expected apiRef in handleBeforeUnmount', event, details );
					}
					if ( gridApiContext.current ) {
						// Perform any necessary cleanup logic here
						const view = getGridView();
						if ( isModelModified && !view?.system ) {
							if ( !details.saveFired ) {
								// bypass setGridView intentionally
								localStorage.setItem( `${ gridViewType }-grid-view`, JSON.stringify( view ) );
								const save = ( view: any ) => viewsApi.save( view ).then( r => {
									r?.id && enqueueSnackbar( `Saved ${ intl.formatMessage( { id: typeLowerCase } ) } View ${ view.category } / ${ view.name }`,
										{
											variant: 'success',
											anchorOrigin: {
												vertical: 'top',
												horizontal: 'center'
											}
										} );
								} );

								const snackbarActions = ( ( snackbarId: any ) =>
									<>
										{/*<Button onClick={ () => {*/ }
										{/*	save(view);*/ }
										{/*	closeSnackbar(snackbarId);*/ }
										{/*} }>*/ }
										{/*	Save*/ }
										{/*</Button>*/ }
										<Button onClick={ () => {
											closeSnackbar( snackbarId );
										} }>
											Cancel
										</Button>
									</> );
								if ( gridView?.permissions?.update ) {
									details.saveFired = true;
									enqueueSnackbar( `Saving ${ intl.formatMessage( { id: typeLowerCase } ) } View ${ view.category } / ${ view.name }...`,
										{
											key: view.id,
											variant: 'success',
											preventDuplicate: true,
											autoHideDuration: 5000,
											anchorOrigin: {
												vertical: 'top',
												horizontal: 'center'
											},
											onClose: ( event, reason, snackbarKey ) => {
												if ( reason === 'timeout' ) { //not canceled
													save( view );
												}
											},
											action: snackbarActions
										} );
								}
							}
						}
					}
				};

				useGridApiEventHandler( gridApiContext, 'unmount', handleBeforeUnmount );

				const handleInputFocus = () => {
					// Open the Autocomplete dropdown when input is focused
					if ( inputRef.current ) {
						inputRef.current.click();
					}
				};
				const auditExport = ( params: any ) => {
					const ids = ( gridFilteredSortedRowIdsSelector( params.apiRef ) ?? [] );
					const res = auditApi.capture(
						{
							typeName: type,
							action: 'export',
							typeIds: ids.filter( id => Number( id ) )
						} );
					return ids;
				};

				return (
					gridColumns ?

						<GridToolbarContainer>
							<Box sx={ { flexGrow: 1 } }>
								{ AddDialog && <Button
									size="small"
									startIcon={ <AddIcon/> }
									onClick={ handleClickAdd }
									disabled={ !canCreateRow }
									title={ intl.formatMessage( { id: `add_${ typeLowerCase }` } ) }
								>
									{ intl.formatMessage( { id: 'add', defaultMessage: 'Add' } ) }
								</Button> }
								<Button
									size="small"
									startIcon={ <Refresh/> }
									onClick={ () => {
										setRefresh( true );
									} }
									title={ intl.formatMessage( { id: 'refresh-grid', defaultMessage: 'Refresh Data' } ) }
								>
									{ intl.formatMessage( { id: 'refresh', defaultMessage: 'Refresh' } ) }
								</Button>
								{ slots?.toolbar && slots.toolbar() }
								{ enableBulkEdit && api?.bulkSave && <Button
									size="small"
									startIcon={ <EditIcon/> }
									onClick={ handleClickBulkEdit }
									disabled={ !canCreateRow }
									title={ intl.formatMessage( {
										id: 'bulk_edit_title',
										defaultMessage: `Edit Selected ${ type }`
									} ) }
								>
									{ intl.formatMessage( { id: 'bulk_edit', defaultMessage: 'Bulk Edit' } ) }
								</Button> }
								<GridToolbarColumnsButton disabled={ canUpdateUIGridView ? false : gridView?.system }/>
								<GridToolbarFilterButton/>
								{
									paginationMode == 'client' &&
								<GridToolbarExport
									csvOptions={ { getRowsToExport: auditExport } }
									excelOptions={ { getRowsToExport: auditExport } }
									printOptions={ {
										pageStyle: '.MuiDataGrid-root .MuiDataGrid-main .MuiGrid-container .MuiDataGrid-cell .MuiDataGrid-detailPanel { color: rgba(0, 0, 0, 0.87); }',
										hideFooter: true,
										hideToolbar: true,
									} }
								/>
								}
								{
									(paginationMode == 'server' && serverSideExport) &&
								<Button onClick={serverSideExport}>Export</Button>
								}
							</Box>
							{ enableMeMode && <FormControlLabel
								label='Me Mode' control={<Switch checked={ meMode } onChange={toggleMeMode}/> }
								title={ `Show only my assigned ${ intl.formatMessage( { id: api.typePlural.toLowerCase(), defaultMessage: api.typePlural }) }` }
							/> }
							{ ( canUpdateUIGridView || !disableGridViewSelector ) &&
						<Box sx={ { display: { xs: 'flex', md: 'flex' } } }>
							<GridViewDefinitionDialog
								id={ `${ gridViewType }-grid-view-dialog` }
								view={ editState }
								setView={ setEditState }
								onSave={ saveGridView }
								onClose={ () => {setDialogOpen( false );} }
								open={ dialogOpen }
								categories={ [ ...new Set( [ ...( gridViews?.map( ( v: any ) => v.category ) ?? [] ), 'My Views', 'Shared' ] ) ] }
							/>
							<FormControl variant="standard" sx={ { m: 1, minWidth: 300, margin: '4px' } }>
								<Autocomplete
									sx={ { fontSize: 'small' } }
									autoSelect={ true }
									autoHighlight={ true }
									autoComplete={ false }
									freeSolo={ false }
									disableClearable={ true }
									size="small"
									id="grid-view"
									value={ gridView }
									//disabled={isModelModified}
									onChange={ handleGridViewChange }
									options={ gridViews }
									//autoWidth
									renderInput={ ( params ) => (
										<TextField { ...params } label={ `${ gridView?.category || 'View' }` }
											onFocus={ handleInputFocus } // Handle input focus
											inputRef={ inputRef } // Assign the inputRef to the input element
										/>
									) }
									getOptionLabel={ ( option: UIGridView<R> ) => `${ option.name }${ option.editable
										? ' Edit'
										: '' }` }
									groupBy={ ( option ) => option.category }
									isOptionEqualToValue={ ( option: UIGridView<R>, value: UIGridView<R> ) => {
										return option?.id === value?.id;
									} }
									disablePortal
								/>
							</FormControl>
							{ ( canUpdateUIGridView || !disableGridViewEditing ) &&
							<LoadingButton
								loading={ viewsApi.isLoading }
								loadingPosition="start"
								disabled={ !( !!gridView?.permissions?.update && isModelModified ) }
								size="small"
								onClick={ () => saveGridView( getGridView() ) }
								title={ intl.formatMessage( {
									id: 'save-grid-config',
									defaultMessage: 'Save Grid View'
								} ) }
							>
								<SaveIcon/>
							</LoadingButton>
							}
							<IconButton
								disabled={ ( !gridView?.roles?.read ) }
								size="small"
								onClick={ () => copyGridViewLink( getGridView() ) }
								title={ intl.formatMessage( { id: 'link-grid-config', defaultMessage: 'Copy Link' } ) }
							>
								<LinkIcon/>
							</IconButton>
							{ ( canUpdateUIGridView || !disableGridViewEditing ) && <>
								<IconButton onClick={ handleMenuOpen } title="View actions">
									<GridMenuIcon/>
								</IconButton>

								<Menu
									anchorEl={ anchorEl }
									open={ Boolean( anchorEl ) }
									onClose={ handleMenuClose }>
									<MenuItem
										//size="small"
										onClick={ newGridView }
										title={ intl.formatMessage( {
											id: 'copy-grid-config',
											defaultMessage: 'Create New Grid View'
										} ) }
									><ListItemIcon><FileCopy/></ListItemIcon><ListItemText>Copy</ListItemText>
									</MenuItem>
									<MenuItem
										disabled={ gridView.id && canUpdateUIGridView ? false
											: !gridView?.permissions?.update }
										// size="small"
										onClick={ editGridView }
										title={ intl.formatMessage( {
											id: 'edit-grid-config',
											defaultMessage: 'Edit Grid View'
										} ) }
									>
										<ListItemIcon><EditIcon/></ListItemIcon><ListItemText>Edit</ListItemText>
									</MenuItem>
									<MenuItem
										disabled={ gridView?.system || ( canDeleteUIGridView ? false
											: !gridView?.permissions?.delete ) }
										//size="small"
										onClick={ () => deleteGridView( gridView, gridViews ) }
										title={ intl.formatMessage( {
											id: 'delete-grid-config',
											defaultMessage: 'Delete Grid View'
										} ) }
									><ListItemIcon><Clear/></ListItemIcon><ListItemText>Delete</ListItemText>
									</MenuItem>
									{ canUpdateUIGridView ? <MenuItem
										// size="small"
										onClick={ () => setLoadGridViews( true ) }
										title={ intl.formatMessage( {
											id: 'reset-grid-config',
											defaultMessage: 'Reset Grid View'
										} ) }
									><ListItemIcon><Refresh/></ListItemIcon><ListItemText>Refresh</ListItemText>
									</MenuItem> : null }
								</Menu></> }
						</Box> }
						</GridToolbarContainer>
						: null
				);
			};
		// , [ gridColumns, gridView ] );

	const getActions = useCallback( ( params: GridRowParams<R> ) => {
		const { id, row } = params;
		const isSingleEdit = row.id || (api.isRowEditable && api.isRowEditable(id, row));
		return isSingleEdit ? [
			<GridActionsCellItem key="editAction" icon={ <EditIcon/> } onClick={ editRow( params ) }
				label="Edit"
				showInMenu disabled={ !canEditGridRow( gridView, gridColumns, params ) }/>,
			<GridActionsCellItem key="copyAction" icon={ <CopyAll/> } onClick={ copyRow( params ) }
				label="Copy"
				showInMenu disabled={ !canCopyGridRow( params ) }/>,
			!params.row.deletedAt ?
				<GridActionsCellItem key="deleteAction" icon={ <DeleteIcon/> } onClick={ deleteRow( params ) }
					label="Delete" showInMenu
					disabled={ !canDeleteRow || !params.row?.id || params.row.deletedAt }/>
				: <GridActionsCellItem key="deleteAction" icon={ <Restore/> }
					onClick={ restoreRow( params ) } label="Restore"
					showInMenu
					disabled={ !canDeleteRow || !params.row?.id || !params.row.deletedAt }/>
		] : enableBulkEdit && api?.bulkSave ? [
			<GridActionsCellItem
				icon={ <EditIcon/> }
				label="Edit Group"
				title={ `Edit ${ id }` }
				onClick={ ( params ) => {
					const groupId = id.toString();
					const rowIds = gridApiRef.current.getRowGroupChildren( {
						groupId,
						applyFiltering: true,
						applySorting: true
					} );
					gridApiRef.current.setRowSelectionModel( rowIds );
					const selectedRows: Map<GridRowId, GridRowModel> = gridApiRef.current.getSelectedRows();
					setSelectedRows( selectedRows );
					setShowBulkEditDialog( true );
				} }
				disabled={ !canEditGridRow( gridView, gridColumns, params ) }
				color="inherit"
			/> ] : [];
	}, [ gridView, gridColumns, canCreateRow, canUpdateRow, canDeleteRow, canEditGridRow, canCopyGridRow ] );

	useEffect( () => {
		setGridColumns( [
			...columns.filter( c => c.field !== 'actions' ),
			{
				field: 'actions',
				type: 'actions',
				hideable: false,
				description: 'Actions',
				maxWidth: 20,
				minWidth: 20,
				width: 20,
				getActions,
				editable: false, groupable: false, aggregable: false, filterable: false, disableExport: true
			}
		] );
	}, [ gridView, columns ] );

	const processRowUpdate = useCallback(
		async ( newRow: any, oldRow: any ) => {
			console.info( newRow, oldRow );
			return newRow;
		},
		[],
	);

	const setPaginationModel = ( model: GridPaginationModel, details: GridCallbackDetails ) => {
		setIsModelModified( true );
		paginationModelCallback( model );
	};

	const setFilterModel = ( model: GridFilterModel, details: GridCallbackDetails ) => {
		setIsModelModified( true );
		filterModelCallback( model );

	};
	const setSortModel = ( model: GridSortModel, details: GridCallbackDetails ) => {
		setIsModelModified( true );
		sortModelCallback( model );
	};

	const handleModelModified = ( ) => {
		setIsModelModified(true);
	};

	return (
		gridColumns ?
			<div style={ { height: '100%', width: '100%', alignContent: 'space-around' } }>
				{ AddDialog && <AddDialog
					open={ showAddDialog }
					onClose={ handleClose }
					onSave={ async ( state: any, config: any ) => {
						if ( !api.save ) {
							return;
						}
						const res = await api.save( state, config );
						if ( navigateOnAdd && res?.id && api.typePlural ) {
							navigate( `/${ api.typePlural.toLowerCase() }/${ res.id }` );
						}
						return res;
					} }
				/> }
				<GridViewEditDialog
					apiRef={ gridApiRef }
					open={ showEditDialog }
					onClose={ handleClose }
					onSave={ api.save }
					data={ rowData }
					// setData={ setRowData }
					schema={ rowData?.id ? api.updateSchema : api.createSchema }
					gridView={ gridView }
					columns={ gridColumns }
					typeLabel={ intl.formatMessage( { id: typeLowerCase } ) }
				/>
				{ api.bulkSave &&
				<GridBulkEditDialog
					apiRef={ gridApiRef }
					open={ showBulkEditDialog }
					onClose={ () => {
						setShowBulkEditDialog( false );
						gridApiRef.current.setRowSelectionModel( [] );
					} }
					onSave={ async ( params ) => {
						const { ids, data } = params;
						const saved = await api.bulkSave!( ids, data );
						setRows( ( prevRows ) => mergeUpdatesById( prevRows, saved ) );
						setShowBulkEditDialog( false );
					} }
					data={ selectedRows }
					gridView={ { editable: true } }
					columns={ gridColumns }
					typeLabel={ intl.formatMessage( { id: 'unitTypeUnitSpace', defaultMessage: 'Unit Spaces' } ) }
				/> }

				<DataGridPremium
					apiRef={ gridApiRef }
					loading={ api.isLoading }
					aggregationFunctions={ GridAggregationFunctions }
					rows={ rows }
					rowCount={ rowCount }
					getRowId={ getRowId }
					columns={ gridColumns }
					pagination
					paginationMode={ paginationMode }
					filterMode={ filterMode }
					sortingMode={ sortingMode }
					disableRowGrouping={ false }
					rowGroupingColumnMode={ rowGroupingColumnMode }
					checkboxSelection={ false }
					checkboxSelectionVisibleOnly={ false }
					disableColumnMenu={ canUpdateUIGridView ? false : gridView?.system }
					disableColumnReorder={ canUpdateUIGridView ? false : gridView?.system }
					//FIXME disableSelectionOnClick={ gridView?.editable }
					initialState={ gridView?.state }
					density="compact"
					defaultGroupingExpansionDepth={ gridView?.groupingExpansionDepth ?? groupingExpansionDepth }
					groupingColDef={ ( params ) => {
						const api = gridApiRef.current;
						if ( !api?.getColumn ) {
							return undefined;
						}
						const sum = params.fields.map( f => {
							return api.getColumn( f )?.minWidth ?? 2200;
						} ).reduce( ( accumulator, current ) => accumulator + current, 0 );
						return gridView?.groupingColDef ?? {
							minWidth: sum ?? 100
						};
					} }
					editMode="row"
					getRowHeight={ getRowHeight }
					getDetailPanelHeight={ getDetailPanelHeight }
					getDetailPanelContent={ getDetailPanelContent }
					sortingOrder={ [ 'desc', 'asc' ] }
					slots={ {
						toolbar: CustomToolbar,
						loadingOverlay: LinearProgress,
					} }
					isCellEditable={ ( params ) => false /*gridView?.editable*/ }
					onProcessRowUpdateError={ handleProcessRowUpdateError }
					//onProcessRowUpdateError={async(error)=>console.info(error)}
					processRowUpdate={ processRowUpdate }
					onAggregationModelChange={ handleModelModified }
					onColumnOrderChange={ handleModelModified }
					onColumnWidthChange={ handleModelModified }
					onPinnedColumnsChange={ handleModelModified }
					onColumnVisibilityModelChange={ handleModelModified }
					onRowGroupingModelChange={ handleModelModified }
					onPaginationModelChange={ setPaginationModel }
					onFilterModelChange={ setFilterModel }
					onSortModelChange={ setSortModel }
					onClipboardCopy={ ( e ) => {
						// console.log('clipboardCopy', e);
					} }
					sx={ sx }
					//autosizeOnMount={true}
				/>
			</div>
			: null
	);
}
;


export default CoreDataGrid;
