import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
	Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import BoardItem from './BoardItem';
import { BoardSectionType } from './types';

interface BoardSectionProps extends BoardSectionType {
	children?: any
}


const BoardSection: React.FC<BoardSectionProps> = ( { title, description, items, downloadable, downloadLink, filters, children } ) => {
	return (
		<Grid item xs={ 12 } md={ 12 }key={`BoardSectionGrid-${title ?? ''}`}>
			<Accordion defaultExpanded={ true } key={`BoardSectionAccordion-${title ?? ''}`}>
				<AccordionSummary
					title={ description ?? '' }
					expandIcon={ <ExpandMoreIcon/> }
					aria-controls="panel1-content"
					id="panel1-header"
				>
					<Typography>{ title }</Typography>
					{downloadable && downloadLink && <IconButton href={downloadLink}><DownloadIcon/></IconButton>}
				</AccordionSummary>
				<AccordionDetails>
					<Typography variant="body2">{ description }</Typography>
					<Grid container width="100%" rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 1, md: 2 } } padding={ 1 }>
						{ items?.map( r => (
							<BoardItem key={`BoeardItem-${r.id}`} { ...r } filters={filters} />
						) ) }
						{ children }
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Grid>
	);
};

export default BoardSection;
