import * as React from 'react';
import {
	DataGridPremium,
	GridActionsCellItem,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext
} from '@mui/x-data-grid-premium';
import Page from 'material-ui-shell/lib/containers/Page';
import { useIntl } from 'react-intl';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext';
import { Button, Chip, LinearProgress, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDisplay from '../ErrorDisplay';
import { useSnackbar } from 'notistack';
import { Clear, Refresh } from '@mui/icons-material';
import PageTitle from '../hooks/PageTitle';
import EditVendor from './EditVendor';
import useLocalStorage from '../hooks/LocalStorage';
import { VendorTypes } from '@corespaces/core-io-common';
import GlobalSearch from '../GlobalSearch';

const defaultSortModel=[{ field: 'createdAt', sort: 'desc' }];
const defaultFilterModel={ items:[] };
const defaultColumnVisibilityModel={
	id: false,
	types: true,
	name: true,
	alias: false,
	email: true,
	phone: true,
	createdAt: false,
	updatedAt: false
};
export default function VendorGrid({data, setRefresh, api})
{
	const { enqueueSnackbar } = useSnackbar();
	const handleError = ErrorDisplay();
	const intl = useIntl();
	const { deleteVendor, saveVendor } = api;
	const newVendor = {types:[]};
	const [vendor, setVendor] = React.useState(newVendor);
	const getDate = (params) => params.value ? new Date(params.value): params.value;
	const formatDate = (params) => {
		if (!params.value) return params.value;
		return intl.formatDate(params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		});
	};
	const renderVendorTypeChips = (params) => (
		<Tooltip title={ params.row[params.colDef?.ref] ?? '' }>
			<span className="table-cell-truncate">{
				params?.value?.map(v => v ? <Chip label={ VendorTypes.find(t=>t.type === v)?.label } /> : '')
			}</span>
		</Tooltip>
	);
	PageTitle({ id: 'vendors', defaultTitle: 'Vendors' });
	const [gridState, setGridState] = useLocalStorage('vendor-grid-model',{
		columns: { columnVisibilityModel: defaultColumnVisibilityModel },
		sorting: { sortModel: defaultSortModel },
		filtering: { filterModel: defaultFilterModel },
		pinnedColumns:{
			right: ['updatedAt','actions'],
			left: []
		}
	});
	const ability = useAbility(AbilityContext);
	const [open, setOpen] = React.useState(false);
	const [canCreate] = React.useState(ability.can('create', 'Vendor'));
	const [canEdit] = React.useState(ability.can('update', 'Vendor'));
	const [canDelete] = React.useState(ability.can('delete', 'Vendor'));
	const editRow = React.useCallback(
		(params) => () =>
		{
			setVendor(params.row);
			setOpen(true);
		},
		[]
	);
	const deleteRow = React.useCallback(
		(params) => async () =>
		{
			try
			{
				const data = await deleteVendor(params.row.id);
				if (data && data.deleted)
				{
					setRefresh(true);
					enqueueSnackbar(`Deleted ${ data.deleted || 0 } vendors`, {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					});
				}
			} catch (e)
			{
				await handleError(e);
			}
		},
		[]
	);
	const columns = React.useMemo(() => [
		{ field: 'actions', type: 'actions', maxWidth: 20, minWidth: 20, width: 20,
			getActions: (params) => [
				<GridActionsCellItem icon={ <EditIcon /> } onClick={ editRow(params) } label="Edit" showInMenu disabled={ !canEdit } />,
				<GridActionsCellItem icon={ <DeleteIcon /> } onClick={ deleteRow(params) } label="Delete" showInMenu disabled={ !canDelete } />
			]
		},
		{ field: 'name', headerName: 'Name', minWidth: 300, description: 'Vendor Full Name' },
		{ field: 'alias', headerName: 'Short Name', minWidth: 300, description: 'Vendor Short Name' },
		{ field: 'email', headerName: 'Email', minWidth: 300, description: 'Email Address' },
		{ field: 'phone', headerName: 'Phone', minWidth: 300, description: 'Phone Number' },
		{ field: 'types', headerName: 'Type', minWidth: 300,
			renderCell: renderVendorTypeChips,
			ref: 'types',
			description: 'Vendor Types'
		},
		{ field: 'createdAt', headerName: 'Created', minWidth: 200, type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
		{ field: 'updatedAt', headerName: 'Updated', minWidth: 200, type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		}
	], [
		//editRow
	]);
	const handleClickAdd = async () =>
	{
		setOpen(true);
	};
	const handleClose = (saved) =>
	{
		setOpen(false);
		setVendor(newVendor);
		setRefresh(saved);
	};

	function CustomToolbar()
	{
		const apiRef = useGridApiContext();
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={ <AddIcon /> }
					onClick={ handleClickAdd }
					disabled={ !canCreate }
					title={ intl.formatMessage({ id: 'add_vendor', defaultMessage: 'Add Vendor' }) }
				>
					{ intl.formatMessage({ id: 'add', defaultMessage: 'Add' }) }
				</Button>
				<Button
					size="small"
					startIcon={ <Refresh /> }
					onClick={ () =>
					{
						setRefresh(true);
					} }
					title={ intl.formatMessage({ id: 'refresh-grid', defaultMessage: 'Refresh Data' }) }
				>
					{ intl.formatMessage({ id: 'refresh', defaultMessage: 'Refresh' }) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport />
				<Button
					size="small"
					startIcon={ <Clear /> }
					onClick={ () => {
						apiRef.current.restoreState({
							...gridState,
							columns: { columnVisibilityModel: defaultColumnVisibilityModel },
							sorting: { sortModel: defaultSortModel },
							filter: { filterModel: defaultFilterModel },
							pinnedColumns: {}
						});
					} }
					title={ intl.formatMessage({ id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' }) }
				>
					{ intl.formatMessage({ id: 'reset', defaultMessage: 'Reset' }) }
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<Page
			pageTitle={ intl.formatMessage({ id: 'vendors' }) }
			isLoading={api.isLoading}
			appBarContent={
				<GlobalSearch/>
			}
		>
			<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>
				<EditVendor
					open={ open }
					onClose={ handleClose }
					vendor={ vendor }
					onSave={ saveVendor }
					typeLabel={ intl.formatMessage({id:'vendor',defaultMessage:'Vendor'} )}
				/>
				<DataGridPremium
					rows={ data }
					columns={ columns }
					pagination
					disableRowGrouping={true}
					checkboxSelection={ false }
					checkboxSelectionVisibleOnly={ false }
					initialState={gridState}
					onStateChange={(state)=>setGridState(state)}
					editMode="row"
					sortingOrder={ ['desc', 'asc'] }
					slots={ {
						toolbar: CustomToolbar,
						loadingOverlay: LinearProgress,
					} }
				/>
			</div>
		</Page>
	);
}
