/* eslint-disable react/jsx-key */
import React, {lazy} from 'react';
import ReportAdmin from '../pages/Admin/ReportAdmin';
import OperatingEntities from '../pages/OperatingEntities';
import Partners from '../pages/Partners';
import PartnerDetail from '../pages/Partners/detail';
import WatchlistDetail from '../pages/Watchlists/detail';
import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute';
import Vendors from '../pages/Vendors';
import Tasks from '../pages/Admin/Tasks';
import AuditRecords from '../pages/Audit';
import { Report, ReportHome } from '../pages/Reports';
import CoreGlobalProjectList from '../components/Project/CoreGlobalProjectList';
import Universities from '../pages/Universities';
import Markets from '../pages/Markets';
import MarketDetail from '../pages/Markets/detail';
import IAMRules from '../pages/Admin/IAMRules';
import IAMUsers from '../pages/Admin/IAMUsers';
import IAMUser from '../pages/Admin/IAMUser';
import TaskHistory from '../pages/Admin/TaskHistory';

const SignIn = lazy(() => import('../pages/SignIn/SignIn'));
const About = lazy(() => import('../pages/About'));
const Home = lazy(() => import('../pages/Home'));
const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'));
const ProjectDetail = lazy(() => import('../pages/Projects/detail'));
const Projects = lazy(()=> import('../pages/Projects'));
const PropertyDetail = lazy(()=> import('../pages/Properties/detail'));
const Properties = lazy(()=> import('../pages/Properties'));
const Accounting = lazy(()=> import('../pages/Accounting'));

const routes = [
	{
		path: '/signin',
		exact: true,
		element: (
			<UnauthorizedRoute>
				<SignIn/>
			</UnauthorizedRoute>
		),
	},
	{
		path: '/admin/tasks',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Tasks/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/admin/tasks/history',
		exact: true,
		element: (
			<AuthorizedRoute>
				<TaskHistory/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/admin/iam/rules',
		exact: true,
		element: (
			<AuthorizedRoute>
				<IAMRules/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/admin/iam/users/:id',
		exact: true,
		element: (
			<AuthorizedRoute>
				<IAMUser />
			</AuthorizedRoute>
		),
	},
	{
		path: '/admin/iam/users',
		exact: true,
		element: (
			<AuthorizedRoute>
				<IAMUsers/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/admin/reports',
		exact: true,
		element: (
			<AuthorizedRoute>
				<ReportAdmin/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/about',
		exact: true,
		element: <About/>,
	},
	{
		path: '/my_account',
		exact: true,
		element: (
			<AuthorizedRoute>
				<MyAccount/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/home',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Home/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/projects',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Projects/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/projects/global',
		exact: true,
		element: (
			<AuthorizedRoute>
				<CoreGlobalProjectList/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/projects/:id/*',
		element: (
			<AuthorizedRoute>
				<ProjectDetail/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/properties',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Properties/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/communities',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Properties/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/properties/:id/*',
		element: (
			<AuthorizedRoute>
				<PropertyDetail/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/communities/:id/*',
		element: (
			<AuthorizedRoute>
				<PropertyDetail/>
			</AuthorizedRoute>
		),
	},
	// {
	// 	path: '/communities/:id/reports',
	// 	element: (
	// 		<AuthorizedRoute>
	// 			<PropertyDetail/>
	// 		</AuthorizedRoute>
	// 	),
	// },
	{
		path: '/communities/:id/reports/*',
		element: (
			<AuthorizedRoute>
				<PropertyDetail/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/watchlist/:id/*',
		element: (
			<AuthorizedRoute>
				<WatchlistDetail/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/markets',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Markets/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/markets/:id/*',
		element: (
			<AuthorizedRoute>
				<MarketDetail/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/universities',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Universities/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/vendors',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Vendors/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/partners',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Partners/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/partners/:id/*',
		exact: true,
		element: (
			<AuthorizedRoute>
				<PartnerDetail/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/reports/',
		exact: true,
		element: (
			<AuthorizedRoute>
				<ReportHome/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/reports/:id',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Report/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/activity',
		exact: true,
		element: (
			<AuthorizedRoute>
				<AuditRecords/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/accounting',
		exact: true,
		element: (
			<AuthorizedRoute>
				<Accounting/>
			</AuthorizedRoute>
		),
	},
	{
		path: '/operating-entities',
		exact: true,
		element: (
			<AuthorizedRoute>
				<OperatingEntities/>
			</AuthorizedRoute>
		),
	},
];

export default routes;
