import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Select from './Select';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Lock } from '@mui/icons-material';
import PropertyAutocomplete from './Property/PropertyAutocomplete';
import APIAutocomplete from './APIAutocomplete';
import { ProjectTypes } from '@corespaces/core-io-common';
import useGridHelper from './hooks/GridHelper';
import PercentageField from './PercentageField';

const InputField = (props) => {
	const intl = useIntl();
	const { MASK } = useGridHelper();
	const readOnly = props?.readOnly ?? props.inputProps?.readOnly;
	const disabled = props?.disabled ?? props.inputProps?.disabled;
	const inputProps = {
		readOnly,
		disabled,
		...props.inputProps,
		endAdornment: readOnly ? <InputAdornment position="end" ><Lock fontSize='small'/></InputAdornment>: undefined
	};
	if (props.masked) {
		return <TextField { ...props } InputProps={ inputProps } value={MASK} />;
	}
	switch (props.type) {
	case 'boolean': {
		let options = [
			{ code: true, name: intl.formatMessage({ id: 'true', defaultMessage: 'Yes' })},
			{ code: false, name: intl.formatMessage({ id: 'false', defaultMessage: 'No' })}
		];
		return <Select
			{ ...props }
			InputProps={ inputProps }
			options={ options }
		/>;
	}
	case 'currency':
		// return <CurrencyField
		// 	{ ...props }
		// 	scale='M'
		// 	key={ props.id }
		// />;
		return <TextField type='number' { ...props } InputProps={ inputProps } />;
	case 'percentage':
		return <PercentageField { ...props } InputProps={ inputProps } />;
	case 'select' :
		return <Select { ...props } InputProps={ inputProps } />;
	case 'autocomplete':
		return <Autocomplete { ...props } InputProps={ inputProps } variant='standard'
			renderInput={(params) => {
				const inputProps = {...params?.inputProps, ...props.inputProps};
				return <TextField { ...props } { ...params } inputProps={inputProps}/>;
			}}
		/>;
	case 'property' :
		return <PropertyAutocomplete { ...props } InputProps={ inputProps } />;
	case 'university' :
		return <APIAutocomplete url='/university' { ...props } InputProps={ inputProps } />;
	case 'market' :
		return <APIAutocomplete url='/market' { ...props } InputProps={ inputProps } />;
	case 'project-type' :
		return 	<Autocomplete
			{ ...props }
			disableClearable={true}
			disableCloseOnSelect={true}
			options={ProjectTypes}
			getOptionLabel={(option) => {return `${option.name}`;}}
			renderInput={(params) => {
				const inputProps = {...params?.inputProps, ...props.inputProps};
				return <TextField { ...props } { ...params } inputProps={inputProps}/>;
			}}
			isOptionEqualToValue={(arg1, arg2)=>{
				return (arg1?.code === arg2?.code);
			} }
		/>;
	default:
		return <TextField { ...props } InputProps={ inputProps } />;
	}
};
InputField.propTypes = {
	...TextField.propTypes,
	type: PropTypes.string,
};

export default InputField;