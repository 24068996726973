// @ts-ignore
import { useState } from 'react';
import useApiClient from './common/api-client';
import { ApiCore } from './common/api-core';
import { AxiosRequestConfig } from 'axios';

export interface ClickupStatus {
	status:string
}
export interface ClickupTask {
	id:number,
	name: string,
	due_date: Date,
	status: ClickupStatus
}

const baseURL = '/api';
const namespace = 'clickup';

const apiOptions = {
	getAll: true,
	getSingle: true,
	post: true,
	put: true,
	patch: true,
	delete: true,
	url: namespace,
};

export const useClickupApi = ( errorCallback: any ) => {
	const client = useApiClient( { baseURL } );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ hasError, setHasError ] = useState( false );
	const api = new ApiCore( apiOptions, client );

	const handleResponse = ( response: any ) => {
		setIsLoading( false );
		return response.data;
	};

	const handleError = ( response: any ) => {
		setIsLoading( false );
		console.dir( response );
		const isError = response.status >= 400;
		setHasError( isError );
		if ( !isError ) {
			return;
		}

		// if api error, use that, otherwise, get response message
		let data;
		if ( typeof response.data === 'object' ) {
			data = response.data;
		}
		else {
			data = {
				code: response.status,
				message: response.statusText
			};
		}
		if ( typeof errorCallback === 'function' ) {
			return errorCallback( data );
		}
		throw data;
	};

	interface GetTasksConfig extends AxiosRequestConfig {
		params: {
			listId?: string,
			spaceId?: string,
			propertyId?: string,
			status?: string|string[],
			limit?: number
		}
	}
	const getTasks = async ( config: GetTasksConfig ): Promise<ClickupTask[]> => {
		setIsLoading( true );
		const url = `${ namespace }/tasks`;
		console.dir( url );

		return api.getProvider()
			.getAll( url, config )
			.then( handleResponse )
			.catch( handleError );
	};

	return {
		isLoading,
		hasError,
		getTasks


	};
};
