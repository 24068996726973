import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
// @ts-ignore
import Page from 'material-ui-shell/lib/containers/Page';
// @ts-ignore
import {useAuth} from 'base-shell/lib/providers/Auth';
import {useSnackbar} from 'notistack';

import PageTitle from '../../components/hooks/PageTitle';
import ErrorDisplay from '../../components/ErrorDisplay';

import {useAdminApi} from '../../services/admin-api';
import {TaskExecution, TaskJobSchedule} from '../../components/Admin/SyncJobTypes';


import SyncIcon from '@mui/icons-material/Sync';
import {
	DataGridPremium, GridActionsCellItem,
	GridColDef,
	GridRenderCellParams,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
	useGridApiRef, useKeepGroupedColumnsHidden
} from '@mui/x-data-grid-premium';

import {Button, LinearProgress, Typography} from '@mui/material';
import {GridRowParams} from '@mui/x-data-grid';
import AdminToolbar from '../../components/Admin/AdminToolbar';
import CronDisplay from '../../components/scheduling/ChronDisplay';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import EditIcon from '@mui/icons-material/Edit';
import JobRunNowDialog from '../../components/Admin/JobRunNowDialog';
import {GridRowIdGetter} from '@mui/x-data-grid/models/gridRows';
import JobEditDialog from '../../components/Admin/JobEditDialog';


export type TaskGridRow = TaskJobSchedule & { taskName: string }

const Tasks = () => {
	const intl = useIntl();
	const handleError = ErrorDisplay();
	const [loading, setLoading] = useState(false);
	const apiRef = useGridApiRef();
	const [pageSize, setPageSize] = useState(50);
	const [selectedRunNow, setSelectedRunNow] = useState<TaskJobSchedule>();
	const [selectedEdit, setSelectedEdit] = useState<TaskJobSchedule>();

	const {
		getAllTasks,
		rescheduleAllJobs,
		updateJob,
		runNow,
		isLoading
	} = useAdminApi(handleError);
	const {auth, setAuth} = useAuth();
	const [refresh, setRefresh] = useState(true);
	const [model, setModel] = useState<{ taskGridRows: TaskGridRow[] }>({
		taskGridRows: []
	});
	const {enqueueSnackbar} = useSnackbar();
	PageTitle({id: 'admin', defaultTitle: 'Admin'});

	useEffect(() => {
		if (refresh && auth.isAuthenticated) {
			getAllTasks()
				.then(data => {
					const taskGridRows = data?.map(it => {
						return {
							...it,
							taskName: it.taskDefinition?.name
						};
					});
					let newModel = {
						taskGridRows
					};
					setRefresh(false);
					setModel(newModel);
				});
		}
	}, [refresh, auth]);

	const handleEditClick = (row: TaskGridRow) => {
		apiRef.current.startRowEditMode({id: row.id});
		setSelectedEdit(row);
	};

	const handleOpenRunNowDialog = (row: TaskGridRow) => {
		setSelectedRunNow(row);
	};
	const rows = useMemo(() => {
		return model?.taskGridRows || [];
	}, [model]);

	const handleRescheduleAllJobs = useCallback(() => {
		rescheduleAllJobs()
			.then((results) => {
				if (results) {
					enqueueSnackbar('Jobs Rescheduled', {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						},
					});
					setLoading(false);
				}
			});
	}, [model]);

	const handleRunNowClose = () => setSelectedRunNow(undefined);
	const handleEditClose = () => {
		if (selectedEdit) {
			apiRef.current.stopRowEditMode({
				id: selectedEdit.id,
				ignoreModifications: true
			});
		}
		setSelectedEdit(undefined);
	};

	const handleRunNow = useCallback(async (task: TaskExecution, job: TaskJobSchedule) => {
		try {
			setLoading(true);
			let data = await runNow(task, job.id);
			if (data) {
				const {message = 'Job Queued for Execution'} = data;
				enqueueSnackbar(message, {
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				});
				setLoading(true);
			}
			return data;
		} finally {
			setLoading(false);
		}
	}, []);

	const handleUpdateJob = async (job: TaskJobSchedule) => {
		console.log('handleUpdateJob');
		console.dir(job);
		try {
			setLoading(true);

			let data = await updateJob(job);
			if (data) {
				enqueueSnackbar('Saved', {
					variant: 'info',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				});
				setLoading(true);
			}
			return data;
		} finally {
			setLoading(false);
		}
	};

	const columns = useMemo<GridColDef[]>(() => [
		{
			field: 'taskName',
			minWidth: 59,
			type: 'string',
			flex: 1,
			headerName: intl.formatMessage({
				id: 'job.taskname',
				defaultMessage: 'Task Name'
			}),
		},
		{
			field: 'name',
			minWidth: 10,
			type: 'string',
			flex: 1,
			headerName: intl.formatMessage({
				id: 'job.jobname',
				defaultMessage: 'Job Name'
			}),
			renderCell: ({row}) => {
				return row.name;
			}
		},
		{
			field: 'organizationKey',
			minWidth: 10,
			type: 'string',
			flex: 1,
			headerName: intl.formatMessage({
				id: 'job.organization',
				defaultMessage: 'Entrata Configuration'
			}),
			renderCell: (params: GridRenderCellParams<TaskJobSchedule>) => {
				const {row: {jobConfiguration}} = params;
				return jobConfiguration?.options?.organizationKey ;
			}
		},
		{
			field: 'scheduled',
			type: 'boolean',
			minWidth: 10,
			editable: true,
			flex: 1,
			headerName: intl.formatMessage({
				id: 'job.scheduled',
				defaultMessage: 'Scheduled'
			}),
		},
		{
			field: 'cronString',
			minWidth: 10,
			flex: 1,
			type: 'string',
			headerName: intl.formatMessage({
				id: 'job.cron_string',
				defaultMessage: 'Schedule'
			}),
			renderCell: (params: GridRenderCellParams<TaskJobSchedule>) => {
				let job: TaskJobSchedule = params.row;
				if (params.rowNode.type === 'group') {
					return (<div></div>);
				}
				return (job.scheduled
					? <CronDisplay cronString={job.cronString}/>
					: <Typography>Unscheduled</Typography>);
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: (params: GridRowParams<TaskGridRow>) => {

				if (!params.row.taskName) {
					return [];
				}

				return [
					<GridActionsCellItem
						icon={<PlayArrowIcon/>}
						label="Run"
						className="textPrimary"
						onClick={() => handleOpenRunNowDialog(params.row)}
					/>,
					<GridActionsCellItem
						icon={<EditIcon/>}
						label="Edit"
						className="textPrimary"
						onClick={() => handleEditClick(params.row)}

						color="inherit"
					/>,

				];
			},
		}
	], []);


	const CustomToolbar = () => {
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={<SyncIcon/>}
					onClick={handleRescheduleAllJobs}
					title={intl.formatMessage({id: 'job.reschedule', defaultMessage: 'Reschedule'})}
				>
					{intl.formatMessage({id: 'job.reschedule', defaultMessage: 'Reschedule'})}
				</Button>

				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
			</GridToolbarContainer>
		);
	};
	const getRowId: GridRowIdGetter<TaskJobSchedule> = (it: TaskJobSchedule) => it.id;
	const groupingColDef = {
		minWidth: 300
	};
	const initialState = useKeepGroupedColumnsHidden({
		apiRef,
		initialState: {
			rowGrouping: {
				model: ['taskName'],
			},
			pagination: {
				paginationModel: {
					pageSize,
				},
			}
		}
	});

	return (
		<Page
			pageTitle={intl.formatMessage({id: 'task_schedule', defaultMessage: 'Task Scheduling'})}
			isLoading={isLoading}
			appBarContent={<AdminToolbar/>}
		>
			<div style={{height: '99%', width: '99%', alignContent: 'space-around'}}>
				<DataGridPremium
					//style={ { flexGrow: 1, width: '95%', height: '50%' } }
					pageSizeOptions={[20, 50, 100]}
					onPaginationModelChange={({pageSize}) => setPageSize(pageSize)}
					apiRef={apiRef}
					getRowId={getRowId}
					rowGroupingColumnMode="single"
					initialState={initialState}
					groupingColDef={groupingColDef}
					columns={columns}
					rows={rows}
					pagination
					editMode={'row'}
					processRowUpdate={handleUpdateJob}
					disableMultipleRowSelection={true}
					sortingOrder={['desc', 'asc']}
					loading={loading}
					slots={{toolbar: CustomToolbar, loadingOverlay: LinearProgress}}
				/>
			</div>
			{
				selectedRunNow &&
				<JobRunNowDialog
					handleRunNow={handleRunNow}
					taskDefinition={selectedRunNow.taskDefinition}
					job={selectedRunNow as TaskJobSchedule}
					open={true}
					handleClose={handleRunNowClose}
				/>}
			{
				selectedEdit &&
				<JobEditDialog
					job={selectedEdit}
					open={true}
					handleClose={handleEditClose}
					apiRef={apiRef}
					onSave={handleUpdateJob }
				/>}

		</Page>
	);
};
export default Tasks;
