import { useAbility } from '@casl/react';
import {
	Clear,
	Refresh
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
	Button,
	LinearProgress,
	Link
} from '@mui/material';
import {
	DataGridPremium,
	GridActionsCellItem,
	GridColDef,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext
} from '@mui/x-data-grid-premium';
import Page from 'material-ui-shell/lib/containers/Page';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import {
	useMemo,
	useState
} from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { AbilityContext } from '../AbilityContext';
import ErrorDisplay from '../ErrorDisplay';
import GlobalSearch from '../GlobalSearch';
import useLocalStorage from '../hooks/LocalStorage';
import PageTitle from '../hooks/PageTitle';
import EditVendor from './EditVendor';
import { Vendor } from './types';

const defaultSortModel = [ { field: 'createdAt', sort: 'desc' } ];
const defaultFilterModel = { items: [] };
const defaultColumnVisibilityModel = {
	id: false,
	types: true,
	name: true,
	alias: false,
	email: true,
	phone: true,
	createdAt: false,
	updatedAt: false
};

type PartnerGridProps = {
	data: Vendor[]
	setRefresh: ( refresh: boolean ) => any;
	api: any
}

const PartnerGrid: React.FC<PartnerGridProps> = ( { data, setRefresh, api } ) => {
	const { enqueueSnackbar } = useSnackbar();
	const handleError = ErrorDisplay();
	const intl = useIntl();
	const { deleteVendor, saveVendor } = api;
	const newVendor: Partial<Vendor> = {
		types: [ 'EQUITY_PARTNER' ]
	};
	const [ vendor, setVendor ] = useState( newVendor );
	const getDate = ( params: any ) => params.value ? new Date( params.value ) : params.value;
	const formatDate = ( params: any ) => {
		if ( !params.value ) {
			return params.value;
		}
		return intl.formatDate( params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		} );
	};

	PageTitle( { id: 'partners', defaultTitle: 'Partners' } );
	const [ gridState, setGridState ] = useLocalStorage( 'partner-grid-model', {
		columns: { columnVisibilityModel: defaultColumnVisibilityModel },
		sorting: { sortModel: defaultSortModel },
		filtering: { filterModel: defaultFilterModel },
		pinnedColumns: {
			right: [ 'updatedAt', 'actions' ],
			left: []
		}
	} );
	const ability = useAbility( AbilityContext );
	const [ open, setOpen ] = useState<boolean>( false );
	const [ canCreate ] = useState<boolean>( ability.can( 'create', 'Partner' ) );
	const [ canEdit ] = useState<boolean>( ability.can( 'update', 'Partner' ) );
	const [ canDelete ] = useState<boolean>( ability.can( 'delete', 'Partner' ) );
	const editRow = React.useCallback(
		( params: any ) => () => {
			setVendor( params.row );
			setOpen( true );
		},
		[]
	);
	const deleteRow = React.useCallback(
		( params: any ) => async () => {
			try {
				const data = await deleteVendor( params.row.id );
				if ( data && data.deleted ) {
					setRefresh( true );
					enqueueSnackbar( `Deleted ${ data.deleted || 0 } partner`, {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					} );
				}
			}
			catch ( e ) {
				handleError( e );
			}
		},
		[]
	);
	const columns: GridColDef<Vendor>[] = useMemo<GridColDef<Vendor>[]>( () => [
		{
			field: 'actions', type: 'actions', maxWidth: 20, minWidth: 20, width: 20,
			getActions: ( params: any ) => [
				<GridActionsCellItem icon={ <EditIcon/> } onClick={ editRow( params ) } label="Edit" showInMenu
					disabled={ !canEdit }/>,
				<GridActionsCellItem icon={ <DeleteIcon/> } onClick={ deleteRow( params ) } label="Delete" showInMenu
					disabled={ !canDelete }/>
			]
		},
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 300,
			description: 'Partner Full Name',
			renderCell: ( params ) => (
				<Link id={ `partner_name_link_${ params.row.id }` } component={ RouterLink }
					to={ `/partners/${ params.row.id }` }>{ params.value }</Link>
			)
		},
		{ field: 'alias', headerName: 'Short Name', minWidth: 300, description: 'Partner Short Name' },
		{ field: 'email', headerName: 'Email', minWidth: 300, description: 'Email Address' },
		{ field: 'phone', headerName: 'Phone', minWidth: 300, description: 'Phone Number' },
		{
			field: 'createdAt', headerName: 'Created', minWidth: 200, type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
		{
			field: 'updatedAt', headerName: 'Updated', minWidth: 200, type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		}
	], [
		//editRow
	] );
	const handleClickAdd = async () => {
		setOpen( true );
	};
	const handleClose = ( saved: any ) => {
		setOpen( false );
		setVendor( newVendor );
		setRefresh( saved );
	};

	function CustomToolbar() {
		const apiRef = useGridApiContext();
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={ <AddIcon/> }
					onClick={ handleClickAdd }
					disabled={ !canCreate }
					title={ intl.formatMessage( { id: 'add_vendor', defaultMessage: 'Add Partner' } ) }
				>
					{ intl.formatMessage( { id: 'add', defaultMessage: 'Add' } ) }
				</Button>
				<Button
					size="small"
					startIcon={ <Refresh/> }
					onClick={ () => {
						setRefresh( true );
					} }
					title={ intl.formatMessage( { id: 'refresh-grid', defaultMessage: 'Refresh Data' } ) }
				>
					{ intl.formatMessage( { id: 'refresh', defaultMessage: 'Refresh' } ) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport/>
				<Button
					size="small"
					startIcon={ <Clear/> }
					onClick={ () => {
						apiRef.current.restoreState( {
							...gridState,
							columns: { columnVisibilityModel: defaultColumnVisibilityModel },
							sorting: { sortModel: defaultSortModel },
							filter: { filterModel: defaultFilterModel },
							pinnedColumns: {}
						} );
					} }
					title={ intl.formatMessage( { id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' } ) }
				>
					{ intl.formatMessage( { id: 'reset', defaultMessage: 'Reset' } ) }
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<Page
			pageTitle={ intl.formatMessage( { id: 'partners', defaultMessage: 'Partners' } ) }
			isLoading={ api.isLoading }
			appBarContent={
				<GlobalSearch/>
			}
		>
			<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>
				<EditVendor
					open={ open }
					onClose={ handleClose }
					vendor={ vendor }
					onSave={ saveVendor }
					typeLabel={  intl.formatMessage({id:'partner',defaultMessage:'Partner'} )}
				/>
				<DataGridPremium
					rows={ data }
					columns={ columns }
					pagination
					disableRowGrouping={ true }
					checkboxSelection={ false }
					checkboxSelectionVisibleOnly={ false }
					initialState={ gridState }
					onStateChange={ ( state ) => setGridState( state ) }
					editMode="row"
					sortingOrder={ [ 'desc', 'asc' ] }
					slots={ {
						toolbar: CustomToolbar,
						loadingOverlay: LinearProgress,
					} }
				/>
			</div>
		</Page>
	);
};
export default PartnerGrid;
