import Chip, { ChipProps } from '@mui/material/Chip';
import capitalize from 'lodash/capitalize';
import React, { FC } from 'react';
import { ReportStateType } from './types';

interface ReportStateChipProps extends ChipProps {
	state?: ReportStateType,
	terminalState?: string
}

export const ReportStateChip: FC<ReportStateChipProps> = ( { state = 'draft', terminalState = 'submitted', ...props }) => {

	return (
		<Chip label={capitalize(state ?? 'draft')} sx={{color: state !== terminalState ? 'red':'green'}} {...props }/>
	);
};
