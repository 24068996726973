import React, {
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import {
	Grid,
	TextField,
} from '@mui/material';
import { usePropertyApi } from '../../../../services/property-api';
import {
	ReportTemplate,
	useReportingApi
} from '../../../../services/reporting-ui-api';
import DisplayField from '../../../DisplayField';
import ErrorDisplay from '../../../ErrorDisplay';
import LookTileContent from '../../../Looker/LookTileContent';
import { ReportContext } from '../../../Reports/ReportContext.js';
import { ReportDialogContentProps } from '../../../Reports/ReportDialog.js';
import { ReportState } from '../../../Reports/types';
import Tile from '../../../Tile.js';
import { PropertyContext } from '../../PropertyContext.js';
import PropertyStaffingContent, {
	initializeRows,
	PropertyStaffingType
} from '../../PropertyStaffing';
import {
	Property,
	PropertyContact
} from '../../types.js';
import ClickupTasksTextField from './ClickupTasksTextField';
const maxLength = 120;
export interface PropertyUpdateReportState {
		communityName: string,
		market: string,
		communityManager: string,
		unitCount: number,
		bedCount: number,
		retailArea: number,
		preleaseNumbers: any,
		weeklyLeasingStats1: any,
		weeklyLeasingStats2: any,
		cyPreleasePercentages: any,
		cyPreleaseNumbers: any
		preleaseYoYComparison: any,
		marketComparisonChart: any,
		marketComparisonTable: any,
		residentEvents: string,
		marketOutreach: string,
		marketInsight: string,
		currentConcessions: string,
		operationsInsight: string,
		leasingRecommendation: string,
		staffing: PropertyStaffingType,
		staffingComment: string,
		status: string,
}

interface PropertyUpdateProps extends ReportDialogContentProps{
	property: Partial<Property>,
}

const TYPE = 'Property';
const NAME = 'weekly_update';
const VERSION = 1;

const EMPTY_VALUE = window.env.reporting?.defaultEmptyValue ?? '';

const PropertyWeeklyUpdateReport: React.FC<PropertyUpdateProps> = ( { initialState, canUpdate, onChange, ...props }) => {
	const reportingApi = useReportingApi(ErrorDisplay);
	const propertyApi = usePropertyApi( ErrorDisplay );
	const [property] = useContext(PropertyContext) ?? [];
	const [report] = useContext(ReportContext);
	const [template, setTemplate] = useState<ReportTemplate>();
	const [contacts, setContacts] = useState<PropertyContact[]>();
	const [state, setState, ] = useState<PropertyUpdateReportState>( report.data );
	const [readonly, setReadonly] = useState<boolean>(!!report.submitted);
	const [refresh, setRefresh] = useState<boolean>(!!props?.refresh);
	const updateState = useCallback((newState: Partial<PropertyUpdateReportState>) => {
		setState((prevState)=>{
			return {
				...prevState,
				...newState
			};
		});
		onChange( { data: newState });
	},[onChange]);

	const handleOnChange = useCallback((event: any)=>updateState( { [event.target.id]: event.target.value } ),[]);
	useEffect( () => {
		setReadonly(!!report.submitted);
		setRefresh(!!props.refresh);
	}, [report.submitted] );
	useEffect(()=>{
		reportingApi.getReportTemplate( TYPE, NAME, VERSION )
			.then( newTemplate => {
				setTemplate( newTemplate );
			} );
		if (property?.id) {
			propertyApi.getContacts( property?.id )
				.then( contacts => {
					setContacts( contacts );
				} );
		}
	},[property?.id]);

	useEffect( () => {
		let newState: Partial<ReportState> = {
			type: TYPE,
		};
		if (template?.id) {
			newState = {
				...newState,
				templateId: template.id,
				version: template.version,
			};
		}
		onChange(newState);
	}, [onChange, template] );

	useEffect( () => {
		let newState: Partial<ReportState> = {
			type: TYPE,
		};
		if (property?.id) {
			newState = {
				...newState,
				title: `Weekly Partner Report - ${property?.name}`,
				typeId: property?.id,
			};
			onChange(newState);
			updateState({
				communityName: property.name,
				market: property.market?.name ?? template?.config?.market?.defaultValue ?? EMPTY_VALUE,
				communityManager: contacts?.find( (pc:PropertyContact) => pc.role === 'Community Manager' )?.contact?.name ?? template?.config?.communityManager?.defaultValue ?? EMPTY_VALUE,
				unitCount: property.unitCount ?? template?.config?.unitCount?.defaultValue ?? EMPTY_VALUE,
				bedCount: property.bedCount ?? template?.config?.bedCount?.defaultValue ?? EMPTY_VALUE,
				retailArea: property.retailArea ?? template?.config?.retailArea?.defaultValue ?? EMPTY_VALUE,
				staffingComment: state?.staffingComment ?? template?.config?.staffingComment?.defaultValue ?? '',
				status: state?.status ?? template?.config?.status?.defaultValue ?? ''
			});
		}
	}, [property, template, onChange, updateState] );

	useEffect(()=>{
		updateState({
			communityManager: contacts?.find( (pc:PropertyContact) => pc.role === 'Community Manager' )?.contact?.name ?? template?.config?.communityManager?.defaultValue ?? EMPTY_VALUE,
		});

	},[contacts, template?.config?.communityManager?.defaultValue, updateState]);

	useEffect(()=>{
		setRefresh(!!props?.refresh);
	},[props.refresh]);

	useEffect(() => {
		if (!report?.id && !state?.staffing) {
			reportingApi.getReports( {
				params: {
					limit: 1,
					filters: {
						type: TYPE, typeId: property?.id,
						template: { type: TYPE, name: NAME },
						submitted: true
					}
				}
			} ).then( r => {
				if (r && r.length && r[0]?.id) {
					const newState = {
						...state,
						staffing: r[0]?.data?.staffing,
					};
					// setState(newState);
					updateState({staffing: r[0]?.data?.staffing});
				} else {
					const rows = initializeRows(property);
					const values = rows.map(row=>[row.role, {role: row.role, active: row.active, vacant: row.vacant}]);
					updateState({ staffing: Object.fromEntries(values) });
				}
			} );
		}
	},[property?.id, report?.id, state]);

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between">
					<Grid item><DisplayField
						key='market' id='market' label='Market' sx={{fontSize:'.5em'}}
						value={state?.market ?? property?.market?.name} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='cm' id='cm' label='Community Manager'
						value={state?.communityManager} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='unitCount' id='unitCount' label='# Units'
						value={state?.unitCount} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='bedCount' id='bedCount' label='# Beds'
						value={state?.bedCount} onChange={handleOnChange}
					/></Grid>
					<Grid item><DisplayField
						key='retailArea' id='retailArea' label='Retail SqFt.'
						value={state?.retailArea} onChange={handleOnChange}
					/></Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					{/* Replace the following with your graph components */}
					<Grid item xs={6}>
						<Tile title='Prelease Stats'
							sx={{
								height: '190px',
								fontSize:'smallest'}}>
							<LookTileContent
								id='cyPreleasePercentages'
								lookId={template?.config?.cyPreleasePercentages?.lookId}
								value={state?.cyPreleasePercentages}
								refresh={ refresh }
								sx={ {height: '60px'} }
								filters={ {
									'lease_diary_property_units.community_filter': property?.name
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								id='cyPreleaseNumbers'
								lookId={template?.config?.cyPreleaseNumbers?.lookId}
								value={state?.cyPreleaseNumbers}
								refresh={ refresh }
								sx={ {height: '60px'} }
								filters={ {
									'lease_diary_property_units.community_filter': property?.name,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
						<Tile title='Weekly Leasing Stats' sx={{height: '190px', fontSize:'smallest'}}>
							<LookTileContent
								key='weeklyLeasingStats1'
								id='weeklyLeasingStats1'
								lookId={template?.config?.weeklyLeasingStats1?.lookId}
								value={state?.weeklyLeasingStats1}
								refresh={ refresh }
								sx={ {height: '190px'} }
								filters={ {
									'subject_and_competitor_leasing.community': property?.name
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								key='weeklyLeasingStats2'
								id='weeklyLeasingStats2'
								lookId={template?.config?.weeklyLeasingStats2?.lookId}
								value={state?.weeklyLeasingStats2}
								refresh={ refresh }
								sx={ {height: '140px'} }
								filters={ {
									'lease_diary_property_units.community_for_filter': property?.name,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
					</Grid>
					<Grid item xs={6}>
						<Tile title='Prelease YoY Comparison' sx={{height: '380px', fontSize:'smallest'}}>
							<LookTileContent
								id='preleaseYoYComparison'
								lookId={template?.config?.preleaseYoYComparison?.lookId}
								value={state?.preleaseYoYComparison}
								refresh={ refresh }
								type='image'
								// sx={ {height: '340px'} }
								imageHeight={300}
								// imageWidth={400}
								filters={ {
									'leasing_velocity_cy_vs_py.community': property?.name,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={5}>
						<Tile title='Market Comparison' sx={{height: '700px', fontSize:'smallest'}}>
							<LookTileContent
								id='marketComparisonChart'
								lookId={template?.config?.marketComparisonChart?.lookId}
								title='Market Comparison'
								value={state?.marketComparisonChart}
								refresh={ refresh }
								sx={ {height: '360px'} }
								//imageWidth={460}
								imageHeight={355}
								type='image'
								filters={ {
									'subject_and_competitor_leasing.community': property?.name,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
							<LookTileContent
								id='marketComparisonTable'
								lookId={ template?.config?.marketComparisonTable?.lookId } // '120' }
								title='Market Comparison'
								value={state?.marketComparisonTable}
								refresh={ refresh }
								sx={ {height: '360px'} }
								// imageWidth={460}
								imageHeight={100}
								cropHeight={50}
								cropWidth={650}
								type='image'
								filters={ {
									'subject_and_competitor_leasing.community': property?.name,
								} }
								readonly={readonly}
								onChange={handleOnChange}
							/>
						</Tile>
					</Grid>
					<Grid item xs={4}>
						<Tile title={'Property Leasing Activity & Insight'} sx={{height: '470px', fontSize:'smallest'}}>
							<ClickupTasksTextField
								id='residentEvents'
								propertyId={property?.id}
								spaceId={template?.config?.residentEvents?.spaceId}
								status={template?.config?.residentEvents?.status ?? 'resident events' }
								sx={{marginTop:'8px', fontSize:'small'}}
								label={'Resident Events'}
								value={state?.residentEvents}
								onChange={handleOnChange}
								multiline maxRows={3} fullWidth required size='small'
								inputProps={ {readOnly: !canUpdate || readonly, maxLength:120 }}
								readonly={readonly}
								rowFormat={'MARKETING_CALENDAR'}
							/>
							<ClickupTasksTextField
								id='marketOutreach' sx={{marginTop:'8px', fontSize:'smaller'}} label={'Market Outreach'}
								multiline maxRows={3} fullWidth required size='small'
								value={state?.marketOutreach}
								propertyId={property?.id}
								spaceId={template?.config?.marketOutreach?.spaceId}
								status={template?.config?.marketOutreach?.status ?? 'outreach'}
								onChange={handleOnChange}
								inputProps={ {readOnly: !canUpdate || readonly, maxLength:120 }}
								readonly={readonly}
								rowFormat={'MARKETING_CALENDAR'}
							/>
							<ClickupTasksTextField
								id='currentConcessions' sx={{marginTop:'8px', fontSize:'smaller'}} label={'Current Concessions'}
								multiline maxRows={3} fullWidth required size='small'
								propertyId={property?.id}
								spaceId={template?.config?.currentConcessions?.spaceId}
								status={template?.config?.currentConcessions?.status ?? 'incentives/specials'}
								value={state?.currentConcessions}
								onChange={handleOnChange}
								inputProps={ {readOnly: !canUpdate || readonly, maxLength:120 }}
								readonly={readonly}
								rowFormat={'MARKETING_CALENDAR'}
							/>
							<ClickupTasksTextField
								id='leasingRecommendation' sx={{marginTop:'8px', fontSize:'smaller'}} label='Recommendation'
								title='i.e. Pace/Traffic'
								multiline maxRows={3} fullWidth required size='small' value={state?.leasingRecommendation}
								onChange={handleOnChange}
								inputProps={ {readOnly: !canUpdate || readonly, maxLength:120 }}
								readonly={readonly}
							/>
						</Tile>
						<Tile title='Key Dates & Market Insight' sx={{height: '115px', fontSize:'smallest'}}>
							<ClickupTasksTextField
								id='marketInsight'
								propertyId={property?.id}
								spaceId={template?.config?.marketInsight?.spaceId}
								status={template?.config?.marketInsight?.status}
								sx={{marginTop:'8px', fontSize:'smaller'}}
								label={''}
								value={state?.marketInsight}
								onChange={handleOnChange}
								multiline maxRows={3} fullWidth required size='small'
								inputProps={ {readOnly: !canUpdate || readonly, maxLength:120 }}
								readonly={readonly}
								rowFormat={'MARKETING_CALENDAR'}
							/>
						</Tile>
						<Tile title='Facilities, Maintenance & Operations' sx={{height: '115px', fontSize:'smallest'}}>
							<ClickupTasksTextField
								id='operationsInsight'
								propertyId={property?.id}
								listId={template?.config?.operationsInsight?.listId }
								sx={{marginTop:'8px', fontSize:'smaller'}}
								label={''}
								value={state?.operationsInsight}
								onChange={handleOnChange}
								multiline maxRows={3} fullWidth required size='small'
								inputProps={ {readOnly: !canUpdate || readonly, maxLength:120 }}
								readonly={readonly}
							/>
						</Tile>
					</Grid>
					<Grid item xs={3}>
						<Tile title="Current Staffing" sx={ { height: '700px', fontSize: 'smallest' } }>
							<PropertyStaffingContent
								id="staffing"
								property={ property }
								sx={ { margin: '0px', fontSize: 'smaller' } }
								value={ state?.staffing }
								canUpdate={canUpdate}
								onChange={ ( event: any ) => updateState( { [event.target.id]: event.target.value } ) }
								readonly={readonly}
							/>
							<br />
							<TextField
								id="staffingComment"
								label="Staffing Commentary"
								variant="standard"
								multiline maxRows={3} fullWidth required size="small"
								error={ (state?.staffingComment?.length ?? 0) > maxLength}
								helperText={<span style={{ color: 'darkmagenta' }}>
									{(( maxLength && state?.staffingComment?.length ) ?? 0) > maxLength ? 'Max text length exceeded.' :
										((maxLength && state?.staffingComment?.length) ?? 0) === maxLength ? `Max text length is ${ maxLength }` : '' }</span>
								}
								defaultValue={ state?.staffingComment ?? '' }
								inputProps={ {readOnly: !canUpdate || readonly, maxLength }}
								onChange={ ( event: any ) => updateState( { [event.target.id]: event.target.value } ) } />
							<br />
							<TextField
								id="status"
								label="Follow Up Items & Status"
								variant="standard"
								multiline maxRows={3} fullWidth required size="small"
								defaultValue={ state?.status }
								error={ (state?.status?.length ?? 0) > maxLength}
								helperText={<span style={{ color: 'darkmagenta' }}>
									{(( maxLength && state?.status?.length ) ?? 0) > maxLength ? 'Max text length exceeded.' :
										((maxLength && state?.status?.length) ?? 0) === maxLength ? `Max text length is ${ maxLength }` : '' }</span>
								}
								inputProps={ {readOnly: !canUpdate || readonly, maxLength }}
								onChange={ ( event: any ) => updateState( { [event.target.id]: event.target.value } ) } />
						</Tile>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PropertyWeeklyUpdateReport;
