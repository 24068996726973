import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import {
	JSX,
	useEffect,
	useMemo
} from 'react';
import styled from '@emotion/styled';
import {
	CardContent,
	CardContentProps,
	CardMedia,
	debounce,
	Fade,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
// @ts-ignore
import { useAuth } from 'base-shell/lib/providers/Auth';
import { useReportingApi } from '../../services/reporting-ui-api';
import Box from '@mui/material/Box';
import DisplayField from '../DisplayField';
import ErrorDisplay from '../ErrorDisplay.js';

const CenteredContent = styled(CardContent)(({ theme }) => ({
	padding: 0
}));


interface LookTileContentProps extends CardContentProps {
	id: string,
	lookId: string,
	filters?: object,
	type?: string
	imageHeight?: number,
	imageWidth?: number,
	cropHeight?: number,
	cropWidth?: number,
	value?: any
	readonly?: boolean,
	refresh?: boolean
}

const LookTileContent:React.FC<LookTileContentProps> = ( { id, lookId, filters, type='json', imageHeight, imageWidth, cropHeight, cropWidth, sx, onChange, value, readonly = false, ...props }) => {
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState<any>(value);
	const [error,setError] = React.useState(value && isEmpty(value) ? 'No Results': '');
	const [refresh, setRefresh] = React.useState<boolean>(false);
	const { getLook } = useReportingApi(ErrorDisplay);
	const { auth } = useAuth();

	const rows = Array.isArray(data) ? data : [data];
	const DataTable = ({data}: {data:object|undefined}): JSX.Element|null => {
		if (data) {
			return (<Table><TableBody>
				{ rows.map((row: object) => (
					<TableRow key={`TableRow-${JSON.stringify(row)}`}>
						{ Object.entries(row).map((cell: any)=>(
							<TableCell key={`TableCell-${cell[0]}`}>
								<DisplayField id={ cell[0] } label={ cell[0]} value={ cell[1] } sx={{textAlign:'unset'}}/>
							</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
			</Table>);
		}
		return null;
	};
	const fetch = useMemo(()=>debounce(async(lookId, filters, imageHeight, imageWidth)=>{
		if (readonly) return;
		setLoading(true);
		setRefresh(false);
		setData(undefined);
		setError('');
		const headers: any = { 'Cache-control': 'max-age=0' };
		if (type === 'image') {
			headers.Accept = 'image/png;base64';
		}
		getLook(lookId, {
			headers,
			params: { filters, imageHeight, imageWidth, cropWidth, cropHeight }})
			.then((data) => {
				setData( data );
				if (isEmpty(data)) {
					setError('No Results');
				}
				//@ts-ignore
				onChange && onChange( { target: { id, value: data }});
				setLoading(false);
			})
			.catch((e)=> {
				setData( [] );
				setError(e?.data?.message ?? e?.message ?? e);
			});
	},500), [imageHeight, imageWidth]);

	useEffect(()=>{
		if(auth.isAuthenticated) {
			setRefresh(true);
		}
	},[auth]);

	useEffect(()=>{
		console.debug('LookTileContent.setRefresh', !!props.refresh);
		setRefresh(!!props.refresh);
	},[props.refresh]);

	useEffect(()=>{
		if ( !loading && lookId && (!data || refresh) ) fetch(lookId, filters, imageHeight, imageWidth);
	},[
		loading, refresh, filters, type, imageHeight, imageWidth, lookId, data, fetch
	]);

	return (
		<CenteredContent sx={{overflow:'auto'}} key={`LookTileContent.${id}`}>
			<Box sx={{ width: '100%' }}>
				<Fade in={loading || !auth.isAuthenticated} unmountOnExit>
					<CircularProgress/>
				</Fade>
				<Typography variant='body1' sx={{textAlign: 'center', color:'red' }}>{error}</Typography>
			</Box>
			{type === 'image' ?
				<CardMedia
					sx={{padding: '2px', overflow:'', alignItems:'center'}}
					component="img"
					image={data?.image}
				/> :
				data && <DataTable key={`DataTable-${id}`} data={data}/>
			}
		</CenteredContent>
	);
};

export default LookTileContent;
