import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';
import { useSnackbar } from 'notistack';
import SaveIcon from '@mui/icons-material/Save';
import set from 'lodash/set';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isArray } from 'lodash';
import Box from '@mui/material/Box';

const EditDataDialog = ({
	title,
	open,
	onClose,
	onSave,
	data = {},
	schema,
	children,
	fields,
	typeLabel = '',
	dialogContentText,
	method = 'PATCH',
	showSuccessMessage = true,
	disableAuditNote = false
}) =>
{
	const intl = useIntl();
	const { enqueueSnackbar } = useSnackbar();
	const [errors, setErrors] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [valid, setValid] = React.useState(true);
	const [changed, setChanged] = React.useState(method === 'PUT' ? data : {});
	const [auditNote, setAuditNote] = React.useState('');
	const handleChange = async (id, event, value1, reason, details) =>
	{
		let { target: { value } } = event;
		if (reason) {
			value = value1;
		}
		value = typeof value === 'string' && isEmpty(value) ? null : value;
		if (isArray(value)) {
			value = value.filter(v=>!!v);
		}
		try
		{
			let fSchema;
			try {
				fSchema = schema?.extract(id);
			} catch(e) {
				try {
					fSchema = schema?.extract(id.substring(0, id.indexOf('-')));
					id = id.substring(0, id.indexOf('-'));
				} catch(e) {
					// don't fail here... some fields impls need work to
					// better match schema of posted data which is caught
					// during save
					console.warn('Skipped field validation:', id);
				}
			}
			let { error } = fSchema?.validate(value, { allowUnknown: true }) || {};
			let newErrors = { ...errors };
			delete newErrors[id];
			if (error)
			{
				newErrors[id] = error.message;
			}
			setErrors(newErrors);
			setValid(!errors);
			setChanged({ ...changed, [id]: value });
			return true;
		} catch (e)
		{
			const newErrors = { ...errors, [id]: e.message };
			setErrors(newErrors);
			setValid(!errors);
		}
	};

	const validate = async (changed) =>
	{
		let { error } = schema?.validate(changed, { abortEarly: false, allowUnknown: true }) || {};
		if (error)
		{
			const errors = error.details.map(d =>
			{
				return {
					key: d.context.key,
					message: d.message
				};
			})
				.reduce(function(map, obj)
				{
					map[obj.key] = obj.message;
					return map;
				}, {});
			setErrors(errors);
			setValid(false);

			const messages = error.details.map(d => `${ d.message }`);
			enqueueSnackbar(messages.join('.  '), {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center'
				}
			});
			return false;
		}
		return true;
	};

	const handleClose = (event, reason) => {
		if (['backdropClick'].includes(reason)) {
			return;//ignored event
		}
		event.preventDefault();
		clearState();
		onClose(false);
	};

	const clearState = () =>
	{
		setChanged({});
		setValid(true);
		setErrors({});
	};

	const handleError = async (e) =>
	{
		let { message, errors } = e;
		if (errors)
		{
			message = `${ message }: ${ errors.flatMap(e => e.message)
				.join(', ') }`;
		}
		enqueueSnackbar(message, {
			variant: 'error',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			}
		});
	};
	const hasChanges = () =>
	{
		return Object.keys(changed).length > 0;
	};
	const isValid = () =>
	{
		return Object.keys(errors).length === 0;
	};
	const handleSave = async (e) =>
	{
		e.preventDefault();
		if (!hasChanges())
		{
			return;
		}

		const payload = method === 'PUT' ? {...data} : { id: data?.id };
		Object.keys(changed)
			.forEach(key =>
			{
				set(payload, key, changed[key]);
			});

		let valid = await validate(payload);
		if (!valid)
		{
			return;
		}
		try
		{
			setLoading(true);
			const config = auditNote ? {headers: {'x-audit-note': auditNote }} : undefined;
			const result = await onSave(payload, config);
			if (result && result.id)
			{
				if (showSuccessMessage)
				{
					enqueueSnackbar(`Saved ${ typeLabel }`, {
						variant: 'success',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					});
				}
				clearState();
				onClose(result);
			}
		} catch (e)
		{
			await handleError(e);
		} finally
		{
			setLoading(false);
		}
	};

	const formatChildren = (children) =>
	{
		if (!children?.length)
		{
			return formatChild(children);
		}
		return children?.map((c) =>
		{
			const { props } = c || {};
			if (c?.type === Symbol.for('react.fragment') && props.children)
			{
				return formatChildren(props.children);
			}
			return c ? formatChild(c) : '';
		}).flat(1);
	};

	const formatChild = (c = {props:{}}) =>
	{
		const { props } = c;
		return props ? {
			...c,
			key: props.id,
			props: {
				...props,
				value: props.masked ? '********' : changed[props.id],
				defaultValue: props.masked ? '********' :  get(data ?? {}, props.id) ?? props.value,
				onChange: (event, value1, reason, details)=>
				{
					return handleChange(props.id, event, value1, reason, details);
				},
				error: !!errors[props.id],
				helperText: errors[props.id] ?? props.description,
				variant: 'standard',
				margin: 'dense',
				fullWidth: true,
			}
		} : '';
	};
	return (
		<Dialog open={ open } onClose={ handleClose } fullWidth={true} disableBackdropClick >
			<DialogTitle>{title ? title : data?.id ? `${intl.formatMessage({id:'edit'})} ${ typeLabel }` : `${intl.formatMessage({id:'add'})} ${ typeLabel }` }</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{ dialogContentText }
				</DialogContentText>
				<form id='editForm' onSubmit={handleSave}>
					{ formatChildren(children ?? fields ?? []) }
				</form>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', flexGrow: 1 }}>
					{!disableAuditNote && <TextField
						id='x-audit-note'
						disabled={disableAuditNote}
						helperText='Enter an optional comment'
						label="Comment" multiline sx={{ width: '90%', margin: '8px' }}
						onChange={(event)=> {
							setAuditNote(event.target.value);
						}}
					/>}
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button onClick={handleClose}>Cancel</Button>
					<LoadingButton
						type="submit"
						form="editForm"
						loading={loading}
						disabled={!hasChanges() && isValid()}
						loadingPosition="start"
						startIcon={<SaveIcon />}
					>
						Save
					</LoadingButton>
				</Box>
			</DialogActions>
		</Dialog>);
};
EditDataDialog.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	data: PropTypes.object,
	value: PropTypes.any,
	description: PropTypes.string,
	schema: PropTypes.object,
	children: PropTypes.array,
	fields: PropTypes.array,
	typeLabel: PropTypes.string,
	dialogContentText: PropTypes.string,
	method: PropTypes.string,
	showSuccessMessage: PropTypes.bool,
	disableAuditNote: PropTypes.bool
};
export { EditDataDialog };
