import IconButton from '@mui/material/IconButton';
import { Badge, Popover, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import * as React from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import { useEffect } from 'react';

export default function AuditInfo({ auditable, sx={}, onClick }) {
	const intl = useIntl();
	const [anchor, setAnchor] = React.useState(null);
	const [badgeCount, setBadgeCount] = React.useState(0);
	const handleOpen = (event) => {
		setAnchor(event.currentTarget);
	};

	const handleClose = (message) => {
		setAnchor(null);

	};
	const open = Boolean(anchor);
	const AuditInfoItem = ({what, when, who})=>{
		if (when) {
			when = intl.formatDate(when,
				{
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric'
				});
			return <Typography sx={{ fontSize: 'small' }}>{what}: {when} {who ? `by ${who.name}` : ''}</Typography>;
		}
		return null;
	};

	useEffect(()=>{
		const startOfToday = new Date();
		startOfToday.setHours(0, 0, 0, 0);
		const updated =  auditable?.updatedAt ? new Date(auditable?.updatedAt).getTime() : 0;
		setBadgeCount(updated >= startOfToday ? 1 : 0);

	},[auditable]);

	return (
		<Box sx={sx} onClick={onClick}>
			<IconButton
				sx={sx}
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handleOpen}
				onMouseLeave={handleClose}
				color="inherit"
			>
				<Badge color="secondary" badgeContent={badgeCount} variant="dot">
					<InfoIcon/>
				</Badge>
			</IconButton>
			<Popover
				id="mouse-over-popover"
				sx={{
					pointerEvents: 'none',
				}}
				open={open}
				anchorEl={anchor}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handleClose}
				disableRestoreFocus
			>
				<AuditInfoItem what={'Created'} when={auditable?.createdAt} who={auditable?.createdBy}/>
				<AuditInfoItem what={'Updated'} when={auditable?.updatedAt} who={auditable?.updatedBy}/>
				<AuditInfoItem what={'Deleted'} when={auditable?.deletedAt} who={auditable?.deletedBy}/>
			</Popover>
		</Box>
	);
}
