import {
	Link,
	MenuItem,
} from '@mui/material';
import React from 'react';
import {
	getGridDefaultColumnTypes,
	GridColDef
} from '@mui/x-data-grid-premium';
import { Link as RouterLink } from 'react-router-dom';
import {
	NamedObjectArrayComparator,
	NamedObjectComparator,
	ObjectIdComparator
} from '../grid/GridColumnComparator';
import { NamedObjectFilter } from '../grid/GridColumnFilterWrapper';
import useGridHelper from '../hooks/GridHelper';
import useIntlPlus from '../hooks/IntlPlus';
import InputField from '../InputField';
import { ReportStateChip } from '../Reports/ReportStateChip';
import Select from '../Select';
import { Vendor } from '../Vendor/types';
import { Watchlist } from './types';

interface WatchlistProps {
	watchlist?: Watchlist
}

function useWatchlistPropertiesGridColumns(props:WatchlistProps) {
	const {watchlist} = props;
	const intl = useIntlPlus();
	const defaultColumnTypes = getGridDefaultColumnTypes();
	const { valueGetter, getDate, formatDateTime, renderComment } = useGridHelper();
	const formatUser = ( params: any ) => {
		if ( !params.value ) {
			return params.value;
		}
		return params.value.name;
	};
	const getVendorNames = (p:any) => {
		return p?.value?.map((pv:Vendor) => pv?.name);
	};
	return React.useMemo<GridColDef[]>( () => [
		{
			field: 'property',
			headerName: intl.formatMessage({ id: 'property', defaultMessage: 'Community' }),
			minWidth: 200,
			description: intl.formatMessage({ id: 'property_name', defaultMessage: 'Community Name' }),
			editable: true,
			readOnly: true,
			valueFormatter: (p) => p?.value?.name,
			filterOperators: defaultColumnTypes['string']?.filterOperators?.map(o => NamedObjectFilter(o)),
			sortComparator: NamedObjectComparator,
			renderCell: (params) => (
				<Link component={RouterLink} to={ `/communities/${ params.row.property?.id }` }>{ params.row.property?.name }</Link>
			),
			renderEditCell: (params)=>(<InputField {...params}  defaultValue={params.row.property?.name} />),
			groupingValueGetter: (v) => v?.row?.property?.name
		},
		{
			field: 'property.propertyGroups', headerName: intl.formatMessage({ id: 'group', defaultMessage: 'Reporting Group' }), minWidth: 200,
			description: 'Asset Reporting Group',
			editable: false,
			valueGetter: ( p ) => {
				let propertyPropertyGroupCurrent = Array.isArray(p.row?.property?.propertyGroups) ? p.row?.property?.propertyGroups[0] : undefined;
				return propertyPropertyGroupCurrent?.propertyGroup?.name;
			},
			groupingValueGetter: ( p ) => {
				let propertyPropertyGroupCurrent = Array.isArray(p.row?.property?.propertyGroups) ? p.row?.property?.propertyGroups[0] : undefined;
				return propertyPropertyGroupCurrent?.propertyGroup?.name;
			},
			filterOperators: defaultColumnTypes['string'].filterOperators?.map(o=>NamedObjectFilter(o)),
			sortComparator: ObjectIdComparator,
		},
		{
			field: 'market', headerName: intl.formatMessage({ id: 'property_market', defaultMessage: 'Market' }),
			description: 'Market or Metro area of Community',
			minWidth: 120,
			editable: false,
			valueGetter: (params) => params.row?.property?.market,
			valueFormatter: (params) => params.value?.name,
			groupingValueGetter: (params) => params.value?.name,
			filterOperators: defaultColumnTypes['string'].filterOperators?.map(o=>NamedObjectFilter(o)),
			sortComparator: NamedObjectComparator,
		},
		{
			field: 'equityPartners', headerName: 'Equity Partners', minWidth: 200,
			editable: false,
			description: 'Equity Partners invested in Community',
			valueGetter: (params) => params.row?.property?.equityPartners,
			valueFormatter: getVendorNames,
			filterOperators: defaultColumnTypes['string'].filterOperators?.map(o => NamedObjectFilter(o)),
			sortComparator: NamedObjectArrayComparator,
			groupingValueGetter: getVendorNames
		},
		{ field: 'state',
			headerName: intl.formatMessage({ id: 'watchlist.state.headerName', defaultMessage: 'State' }),
			description: intl.formatMessage({ id: 'watchlist.state.description', defaultMessage: 'Current state of watchlist monitoring' }),
			editable: true,
			width: 160,
			//@ts-ignore
			renderEditCell: (params)=><Select
				{ ...params }
				options={watchlist?.config?.states ?? []}
				renderOption={(option:string)=>(<MenuItem key={option} value={option}>{option}</MenuItem>)}
			/>
		},
		{ field: 'reason',
			headerName: intl.formatMessage({ id: 'watchlist.state.headerName', defaultMessage: 'Main Concern' }),
			description: intl.formatMessage({ id: 'watchlist.state.description', defaultMessage: 'Main Reason for addition to watchlist' }),
			editable: true,
			width: 160, type: 'string',
			//@ts-ignore
			renderEditCell: (params)=><Select
				{ ...params }
				options={watchlist?.config?.reasons ?? []}
				renderOption={(option:string)=>(<MenuItem key={option} value={option}>{option}</MenuItem>)}
			/>
		},

		/** latest status data **/

		{
			field: 'latestStatus.data.unitCount',
			headerName: '# Units',
			valueGetter,
		},
		{
			field: 'latestStatus.data.bedCount',
			headerName: '# Beds',
			valueGetter,
		},
		{
			field: 'latestStatus.data.retailArea',
			headerName: 'Retail SqFt',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleasePercentages[0][\'CY %\']',
			headerName: 'CY %',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleasePercentages[0][\'CY New %\']',
			headerName: 'CY New %',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleasePercentages[0][\'CY Renewal %\']',
			headerName: 'CY Renewal %',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleasePercentages[0][\'Delta to PY%\']',
			headerName: 'Delta to PY%',
			valueGetter,
		},

		{
			field: 'latestStatus.data.cyPreleaseNumbers[0][\'CY New\']',
			headerName: 'CY New',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleaseNumbers[0][\'CY Leased\']',
			headerName: 'CY Leased',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleaseNumbers[0][\'# Renewals\']',
			headerName: '# Renewals',
			valueGetter,
		},
		{
			field: 'latestStatus.data.cyPreleaseNumbers[0][\'Delta to Comp%\']',
			headerName: 'Delta to Comp%',
			valueGetter,
		},

		{
			field: 'latestStatus.data.weeklyLeasingStats1[0][\'Tours\']',
			headerName: 'Tours',
			valueGetter,
		},
		{
			field: 'latestStatus.data.weeklyLeasingStats1[0][\'Total Leases\']',
			headerName: 'Total Leases',
			valueGetter,
		},
		{
			field: 'latestStatus.data.weeklyLeasingStats1[0][\'Total Traffic\']',
			headerName: 'Total Traffic',
			valueGetter,
		},
		{
			field: 'latestStatus.data.weeklyLeasingStats1[0][\'Conversion Rate\']',
			headerName: 'Conversion Rate',
			valueGetter,
		},

		{
			field: 'latestStatus.data.weeklyLeasingStats2[0][\'Follow-up %\']',
			headerName: 'Follow-up %',
			valueGetter,
		},
		{
			field: 'latestStatus.data.weeklyLeasingStats2[0][\'Lease End\']',
			headerName: 'Lease End',
			valueGetter,
		},
		{
			field: 'latestStatus.data.weeklyLeasingStats2[0][\'Prior 4 Wk Average\']',
			headerName: 'Prior 4 Wk Average',
			valueGetter,
		},
		{
			field: 'latestStatus.data.status',
			headerName: 'Status Commentary',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		{
			field: 'latestStatus.date',
			headerName: 'Status Date',
			valueFormatter: formatDateTime,
			valueGetter,
		},
		{
			field: 'latestStatus.state',
			headerName: 'Status',
			renderCell: ({ value })=><ReportStateChip state={value}/>,
			valueGetter,
		},

		{
			field: 'latestStatus.data.marketInsight',
			headerName: 'Market Insight',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		{
			field: 'latestStatus.data.marketOutreach',
			headerName: 'Market Outreach',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		{
			field: 'latestStatus.data.residentEvents',
			headerName: 'Resident Events',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		{
			field: 'latestStatus.data.operationsInsight',
			headerName: 'Operations Insight',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		{
			field: 'latestStatus.data.leasingRecommendation',
			headerName: 'Leasing Recommendation',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		{
			field: 'latestStatus.data.currentConcessions',
			headerName: 'Current Concessions',
			minWidth: 200,
			renderCell: renderComment,
			valueGetter,
		},
		/** Audit Info **/
		{ field: 'createdAt', headerName: 'Created', width: 160, type: 'date',
			valueFormatter: formatDateTime,
			valueGetter: getDate
		},
		{ field: 'createdBy', headerName: 'Created By', minWidth: 200, type: 'user',
			valueFormatter: formatUser
		},
		{ field: 'updatedAt', headerName: 'Updated', width: 160, type: 'date',
			valueFormatter: formatDateTime,
			valueGetter: getDate
		},
		{ field: 'updatedBy', headerName: 'Updated By', minWidth: 200, type: 'user',
			valueFormatter: formatUser
		},
	], [defaultColumnTypes, formatDateTime, getDate, intl, watchlist?.config?.reasons, watchlist?.config?.states]);
}
export default useWatchlistPropertiesGridColumns;
